<template>
  <div>
    <div>
      <div class="d-flex align-items-center">
        <div class="user-img">
          <img :src="authUser.avatar" :alt="fullName" class="avatar-40 rounded-circle">
        </div>
        <form class="post-text ml-3 w-100">
          <input type="text" :placeholder="placeholderText" :maxlength="limit" class="form-control add-post-text"
            v-model="form.description" style="border:none;" />
        </form>
        <div>
          <img src="@/assets/images/social/schedule-post.jpg" width="100px" class="img-fluid" />
        </div>
      </div>
      <hr />
      <div class="other-option">
        <div class="d-flex align-items-center justify-content-between">
          <div class="iq-card-post-toolbar">
            <AddPostStatusModal title="Who can see this Tweet?" />
          </div>
          <div>
            <v-progress-circular :value="percentsLeft" :rotate="360" :color="limitColor" :width="1">
              {{ symbolsLeft }}
            </v-progress-circular>
          </div>
        </div>
        <div class="d-flex">
          <tag-people-presenter :taggedPeople="post.taggedPeople" />
        </div>
        <div v-if="post.files.length" class="d-flex w-100 mt-2">
          <PostFiles :files="post.files" />
        </div>
        <div v-if="post.videos.length" class="d-flex w-100 mt-2">
          <PostVideos v-if="videoLoaded" :videos="post.videos" />
        </div>
        <div v-if="post.images.length" class="d-flex w-100 position-relative mt-2">
          <PostPhotos  :images="postImages" />
          <b-button class="position-absolute remove-photos" variant="primary" @click="clearPhotos">X</b-button>
        </div>
      </div>
      <hr />
      <div class="text-bold mt-4">Add to your post</div>
      <ul class="d-flex justify-content-around align-items-center list-inline m-0 p-0 mt-2 add-media">
          <li>
            <FileUploader
              :files="post.images"
              title="Select files to begin"
              alt="Photo"
              subTitle="Share images in your post"
              description="(Up to 15 Photos)"
              accept="image/*"
              :limit="15"
              :size-limit="fileSizes.image"
              @onFilesUpdate="updatePhoto"
            />
          </li>
          <li>
            <FileUploader
              :files="post.videos"
              image="video-uploader.jpg"
              icon="vids.svg"
              title="Select video file"
              alt="Video"
              subTitle="Share video in your post"
              accept="video/*"
              :limit="1"
              :size-limit="fileSizes.video"
              @onFilesUpdate="updateVideo"
            >
              <template v-slot:description>
                <div>MP4, WebM or OGG</div>
                <div>Up to 30 minutes</div>
                <div>Less than 2 GB</div>
              </template>
            </FileUploader>
          </li>
          <li>
            <FileUploader
              :files="post.files"
              image="file-uploader.png"
              icon="attachment.svg"
              title="Select file"
              alt="File"
              subTitle="Accepted files PDF, DOC"
              description="(Up to 5 files)"
              accept="application/pdf, application/msword"
              :limit="5"
              :size-limit="fileSizes.document"
              @onFilesUpdate="updateFile"
            />
          </li>
          <li>
            <tag-people-pop-up :taggedPeople="post.taggedPeople" />
          </li>
          <li v-for="(item,index) in tab" :key="index">
            <div class="rounded p-2 pointer cursor-pointer text-center" :title="item.name">
              <a href="#"></a>
              <img :src="renderIco(item.icon)" :alt="item.name" class="img-fluid svg">
            </div>
          </li>
        </ul>
    </div>
    <b-row>
      <b-col class="d-flex mt-4 mb-4" md="12">
        <img src="@/assets/images/icon/schedule.svg" alt="schedule" class="img-fluid svg mr-2">
        <h5>Will sent on
          <span v-if="form.date">
            {{ form.date | formatDateStringDate }}
          </span>
          <span v-if="form.time">at {{ form.time.hh }}:{{ form.time.mm }} {{ form.time.A }}</span>
        </h5>
      </b-col>
    </b-row>
    <b-row class="property-form main-form">
      <b-col md="8">
        <FormDate v-model="form.date" label="Date" required error-message="Please select a date" :submitted="submitted" />
      </b-col>
      <b-col md="4">
        <FormTime v-model="form.time" label="Time" required error-message="Please select a time" :submitted="submitted"
          placeholder="Time" />
      </b-col>
      <b-col md="6">
        <TimeZone required :submitted="submitted" v-model="form.zone" />
      </b-col>
    </b-row>
    <b-row>
      <b-button variant="primary" class="d-block w-100 mt-3" :disabled="!form.description"
        @click="scheduleAction">Schedule</b-button>
      <!-- <b-button variant="secondary" class="d-block w-100 mt-3" :to="{ name: 'social.scheduled' }">Schedule page</b-button> -->
      <b-button variant="secondary" class="d-block w-100 mt-3" v-b-modal.scheduled-page-modal>Schedule page</b-button>
      <b-modal id="scheduled-page-modal" size="lg" title="Scheduled Tweets" hide-footer content-class="rounded"
        no-fade>
        <div class="w-100">
          <div class="mb-4 pb-2 border-bottom" v-for="(item, index) in scheduledPosts" :key="index">
            <div class="d-flex ml-4 mb-2">
              <img src="@/assets/images/icon/schedule.svg" alt="schedule" class="img-fluid svg mr-2">
              <h6>Will send on
                <span>
                  {{ item.date | formatDateStringDate }}
                </span>
                <span v-if="item.time">at {{ item.time.hh }}:{{ item.time.mm }} {{ item.time.A }}</span>
                <span> - {{ item.zone }}</span>
              </h6>
            </div>
            <ul class="row align-items-center list-inline p-0 m-0">
              <li class="col-lg-1">
                <b-form-checkbox
                :id="`scheduled-post-${item.id}`"
                :name="`scheduled-post-${item.id}`"
                v-model="selected"
                :value="item.id"
                />
              </li>
              <li class="col-lg-8">{{ item.content }}</li>
              <li class="col-lg-3"><img src="@/assets/images/page-img/p1.jpg" class="img-fluid"></li>
            </ul>
          </div>
          <div class="d-flex justify-content-between">
            <b-button variant="transparent" class="d-block w-20 mt-3" @click="selectAll">Select All</b-button>
            <b-button variant="danger" class="d-block w-20 mt-3" @click="deleteHandler">Delete</b-button>
          </div>
        </div>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import FormDate from '@/components/homeiz/common/form/FormDate'
import FormTime from '@/components/homeiz/common/form/FormTime'
import TimeZone from '@/components/homeiz/realEstate/form/TimeZone'
import AddPostStatusModal from '@/components/homeiz/feed/AddPostStatusModal'
import FileUploader from '@/components/homeiz/common/form/FileUploaderLinkedin'
import Post from '@/Model/Post'
import { MAX_DOCUMENT_FILE_SIZE, MAX_VIDEO_FILE_SIZE, MAX_IMAGE_FILE_SIZE } from '@/config/file.js'
import TagPeoplePresenter from './tag/TagPeoplePresenter.vue'
import TagPeoplePopUp from './tag/TagPeoplePopUp.vue'
import PostPhotos from '@/components/homeiz/feed/post/PostPhotos'
import PostFiles from '@/components/homeiz/feed/post/PostFiles'
import PostVideos from './post/PostVideos.vue'

export default {
  name: 'SchedulePost',
  components: {
    AddPostStatusModal,
    FileUploader,
    FormDate,
    FormTime,
    TimeZone,
    TagPeoplePresenter,
    TagPeoplePopUp,
    PostPhotos,
    PostFiles,
    PostVideos
  },
  props: {
    author: {
      type: Object,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  inject: ['authUser'],
  validations: {
    form: {
      date: { required },
      time: { required },
      zone: { required }
    }
  },
  created () {
    this.onLoad()
  },
  data () {
    return {
      submitted: false,
      limit: 300,
      tab: [
        {
          icon: '/icon/gif.svg',
          name: ' GIF'
        },
        {
          icon: '/icon/feeling.svg',
          name: ' Feeling'
        }
      ],
      post: {},
      form: {
        description: null,
        images: null,
        date: null,
        time: null,
        zone: null
      },
      fileSizes: {
        image: MAX_IMAGE_FILE_SIZE,
        video: MAX_VIDEO_FILE_SIZE,
        document: MAX_DOCUMENT_FILE_SIZE
      },
      scheduledPosts: [
        {
          id: 1,
          content: 'Post created by the PostModelConstructor and extended post types Ipsum is simply dummy text of the printing and typesetting industry.',
          date: '2023-08-31',
          time: {
            hh: 8,
            mm: 22,
            A: 'am'
          },
          zone: 'Eastern Time'
        },
        {
          id: 2,
          content: 'Lorem ipsum dolor sit amet! Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          date: '2023-08-12',
          time: {
            hh: 4,
            mm: 21,
            A: 'pm'
          },
          zone: 'Pacific Time'
        },
        {
          id: 3,
          content: 'Lorem ipsum 3...',
          date: '2023-08-09',
          time: {
            hh: 6,
            mm: 15,
            A: 'am'
          },
          zone: 'Eastern Time'
        }
      ],
      selected: []
    }
  },
  methods: {
    scheduleAction () {
      try {
        this.$v.$touch()
        this.submitted = true
        if (!this.$v.form.$invalid) {
          this.$bvModal.hide('schedule-modal')
          this.$emit('schedulePost', this.form)
        }
      } catch (e) {

      }
    },
    previewImage: function (event) {
      const files = event.target.files
      Object.keys(files).forEach(i => {
        const file = files[i]
        const reader = new FileReader()
        reader.onload = (e) => {
          this.post.images.push(e.target.result)
        }
        reader.readAsDataURL(file)
      })
    },
    updatePhoto (data) {
      if (data.length) {
        this.post.images = data
        // this.post.images = data.map(image => {
        //   return image.preview
        // })
      } else {
        this.post.images = []
      }
    },
    clearPhotos () {
      this.post.images = []
    },
    updateVideo (data) {
      this.post.videos = data
      this.videoLoaded = true
    },
    updateFile (data) {
      this.post.files = data
    },
    renderIco (ico) {
      return require(`@/assets/images${ico}`)
    },
    selectAll () {
      for (const post of this.scheduledPosts) {
        if (!this.selected.includes(post.id)) {
          this.selected.push(post.id)
        }
      }
    },
    deleteHandler () {
      for (const postId of this.selected) {
        const i = this.scheduledPosts.find(x => x.id === postId)
        this.scheduledPosts.splice(i, 1)
        this.selected = []
      }
    },
    async onLoad () {
      this.post = new Post(
        { user: this.authUser }
      )
      this.isLoaded = true
    }
  },
  computed: {
    postAuthor () {
      return this.author === null ? this.authUser : this.author
    },
    fullName () {
      return `${this.authUser.firstName} ${this.authUser.lastName}`
    },
    placeholderText () {
      return this.placeholder ? this.placeholder : `What's new, ${this.postAuthor.firstName}?`
    },
    symbolsLeft () {
      return this.form.description ? this.limit - Number(Number(this.form.description.length)) : this.limit
    },
    percentsLeft () {
      return this.symbolsLeft / this.limit * 100
    },
    limitColor () {
      if (this.percentsLeft < 20 && this.percentsLeft > 0) {
        return 'orange'
      } else if (this.percentsLeft <= 0) {
        return 'red'
      } else {
        return 'martin-blue'
      }
    },
    postImages () {
      if (this.post.images.length) {
        return this.post.images.map(image => {
          return image.preview
        })
      }
      return []
    }
  }
}
</script>

<style lang="scss"></style>
