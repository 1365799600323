
<template>
  <main>
    <div
      class="rounded p-2 pointer d-flex align-items-center cursor-pointer"
      :title="alt"
      @click="$bvModal.show(`files-modal-${_uid}`)"
    >
      <img :src="renderIco(icon)" :alt="alt" class="img-fluid svg">
    </div>
    <b-modal
      :id="`files-modal-${_uid}`"
      size="lg"
      scrollable
      title="Editor"
      content-class="rounded"
      body-class="bg-white"
      no-fade
      no-close-on-backdrop
      no-close-on-esc
      @close="onCloseHandler"
    >
      <div id="drop-area" class="cursor-pointer">
        <form class="images-form">
          <b-row v-if="files.length">
            <b-col md="8" class="d-flex justify-content-center align-items-center">
              <div v-if="selectedFile" class="uploaeder-image-preview">
                <div v-if="files[selectedFile - 1].preview">
                  <img
                    :src="files[selectedFile - 1].preview"
                    class="img-fluid"
                    :alt="files[selectedFile - 1].name"
                  >
                </div>
                <div v-if="videoLoaded" class="w-100" >
                  <video
                    class="w-100"
                    :src="files[selectedFile - 1].src"
                    controls
                  />
                </div>
                <div v-if="!files[selectedFile - 1].src && !files[selectedFile - 1].preview">
                  <img :src="renderImage(image)" class="img-fluid">
                  <h6 class="text-center">{{ files[selectedFile - 1].name }}</h6>
                </div>
              </div>
            </b-col>
            <b-col md="4">
              <div id="file-uploader-data" class="iq-card iq-card-block iq-card-stretch iq-card-height">
                <div class="iq-card-header d-flex justify-content-between">
                  <div>{{ imageSelected }} of {{ files.length }}</div>
                </div>
                <div class="iq-card-body card-scrollable">
                  <ul v-if="files.length" class="images-preview">
                    <li
                      class="w-50 image-preview"
                      :class="file.preview ? '' : 'doc-file'"
                      v-for="(file, index) in files"
                      :key="index"
                    >
                      <div
                        class="image-wrapper cursor-pointer d-flex"
                        :class="selectedFile - 1 === index ? 'active' : ''"
                        @click="selectFile(index)"
                      >
                        <div>
                          <img v-if="file.preview" :src="file.preview" class="img-fluid" :alt="file.name">
                          <div v-else>
                            <img :src="renderImage(image)" class="img-fluid" :alt="file.name">
                            <div v-if="!file.src" class="d-flex justify-content-center">{{ file.name }}</div>
                          </div>
                        </div>
                      </div>
                      <div>{{ imageCounter(index) }}</div>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul class="file-control-panel d-flex align-items-center justify-content-center mb-0">
                    <li>
                      <div class="action-btn" @click.stop="removeFile">
                        <img src="@/assets/images/icon/trash-bin.svg" alt="Trash" title="Trash" class="img-fluid svg-30"/>
                      </div>
                    </li>
                    <li v-if="files.length < limit">
                      <div class="drag-drop-small action-btn" @dragover.prevent @drop.prevent>
                        <label :for="`file-${_uid}`" @drop="dragFile" class="cursor-pointer">
                          <div>
                            <img src="@/assets/images/icon/add.svg" alt="Add" title="Add" class="img-fluid svg-30"/>
                          </div>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col md="12">
              <div class="drag-drop" @dragover.prevent @drop.prevent>
                <label v-if="!files.length" :for="`file-${_uid}`" @drop="dragFile"
                  class="drag-area-linkedin cursor-pointer">
                  <div>
                    <img class="img-fluid w-30" :src="renderImage(image)" />
                    <div class="text-royal-blue">
                      <slot name="description">
                        <div v-if="description">{{ description }}</div>
                      </slot>
                    </div>
                    <div class="main-title">{{ title }}</div>
                    <div class="description">{{ subTitle }}</div>
                    <div class="btn btn-primary">Upload from computer</div>
                  </div>
                </label>
              </div>
            </b-col>
          </b-row>
          <input :id="`file-${this._uid}`" v-show="false" type="file" :accept="accept" :multiple="multiple" :max="limit"
            @change="uploadFile" />
        </form>
      </div>
      <template #modal-footer>
        <b-button variant="homeiz" @click="onBackHandled">Back</b-button>
        <b-button :disabled="!files.length" variant="primary" @click="onNextHandled">Next</b-button>
      </template>
    </b-modal>
  </main>
</template>

<script>
export default {
  name: 'FileUploaderLinkedin',
  props: {
    title: {
      type: String,
      default: 'Select files to begin'
    },
    icon: {
      type: String,
      default: 'photo.svg'
    },
    alt: {
      type: String,
      default: 'Media'
    },
    image: {
      type: String,
      default: 'image-uploader.jpg'
    },
    subTitle: {
      type: String,
      default: 'We accept JPG, PNG, TIFF (100mb file size)'
    },
    description: {
      type: String,
      default: '(Up to 30 Files)'
    },
    multiple: {
      type: Boolean,
      default: true
    },
    accept: {
      type: String
    },
    sizeLimit: {
      type: Number,
      default: 100000
    },
    limit: {
      type: Number,
      default: 30
    },
    files: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      videoLoaded: false,
      selectedFile: 1
    }
  },
  watch: {
    // files: {
    // handler: function (val) {
    // this.$emit('onPhotoUpdate', this.files)
    // },
    // deep: true
    // }
  },
  methods: {
    dragFile (event) {
      event.dataTransfer.files.forEach(file => {
        this.addFile(file)
      })
    },
    uploadFile (event) {
      event.target.files.forEach(file => {
        this.addFile(file)
      })
    },
    removeFile () {
      event.preventDefault()
      event.stopPropagation()
      this.files.splice(this.selectedFile - 1, 1)
      this.selectedFile--
    },
    addFile (file) {
      if (file.size < this.sizeLimit) {
        if (file['type'].split('/')[0] === 'image') {
          file.preview = URL.createObjectURL(file)
        }

        if (this.files.length < this.limit) {
          this.selectedFile = this.files.length + 1
          this.files.push(file)
        }

        if ((file['type'].split('/')[0] === 'video')) {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.addEventListener('load', r => {
            this.files[this.selectedFile - 1].src = reader.result
            this.videoLoaded = true
          })
        }
      } else {
        alert('File is too big!')
      }
    },
    selectFile (index) {
      this.selectedFile = index + 1
    },
    onBackHandled () {
      this.files = []
      this.selectedFile = 0
      this.$bvModal.hide(`files-modal-${this._uid}`)
      this.$emit('onFilesUpdate', this.files)
    },
    onNextHandled () {
      this.$emit('onFilesUpdate', this.files)
      this.$bvModal.hide(`files-modal-${this._uid}`)
    },
    async onCloseHandler (bvModalEvent) {
      if (this.files.length) {
        bvModalEvent.preventDefault()
        const response = await this.$bvModal.msgBoxConfirm(
          'Are you sure you want to discard the changes you have made?',
          {
            title: 'Discard changes',
            okTitle: 'Discard',
            cancelTitle: 'Cancel',
            cancelVariant: 'transparent'
          })
        if (response) {
          this.selectedFile = 0
          this.files = []
          this.$emit('onFilesUpdate', this.files)
          this.$bvModal.hide(`files-modal-${this._uid}`)
        }
      }
    },
    renderIco (ico) {
      return require(`@/assets/images/icon/${ico}`)
    },
    renderImage (img) {
      return require(`@/assets/images/file/${img}`)
    },
    imageCounter (index) {
      return index < 9 ? `0${index + 1}` : `${index + 1}`
    }
  },
  computed: {
    imageSelected () {
      return this.selectedFile ? this.selectedFile : 1
    }
  }
}
</script>

<style lang="scss">
.drag-drop {
  width: 100%;
}

.drag-area-linkedin {
  width: 100%;
  min-height: 20px;
  padding: 60px 20px 60px 20px;
  text-align: center;
  line-height: 32px;

  i {
    font-size: 24px;
  }

  .main-title {
    font-size: 26px !important;
  }

  .description {
    font-size: 16px;
  }
}

.images-preview {
  list-style: none;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  li {
    padding: 4px;
  }
}

.preview {
  max-width: 100px;
  margin: 5px;
}

.drag-drop-small {
  .drag-area {
    border: none !important;
  }
}

li {
  &.doc-file {
    width: 100%;
  }
}

.doc-file {
  min-height: 40px;

  .remove-image {
    top: 3px;
  }
}

.image-wrapper {
  background: var(--iq-icons-background);
  width: 100%;
  height: 200px;
  border-radius: 7px;
  align-items: center;
  // height: 100%;
}

.file-control-panel {
  display: flex;
  margin-top: 36px;
}

.card-scrollable {
  max-height: calc(60vh - 0.8rem);
  min-height: calc(60vh - 0.8rem);
  overflow-y: auto;
}

.action-btn {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: transparent;
  margin-right: 6px;
}

.image-wrapper {
  &.active {
    border: 4px solid var(--iq-primary);
    border-radius: 7px;
  }
}
</style>
