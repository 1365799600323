<template>
  <div
    class="peep-editor"
  >
    <Editor
            api-key="n3agkh4msoxv3sg4xmfu0t3io04hbpj0d2k3o765nusl6j06"
            model-events="input"
            :init="tinymce"
            :placeholder="placeholder"
            v-model="inputText"
          />
      <!-- <textarea
        class="post-text-area"
        :placeholder="placeholder"
        v-model="inputText"
        :state="!error"
        @input="resize"
        trim
        ref="postarea"
      ></textarea> -->
  </div>
</template>

<script>
import { requiredIf, maxLength } from 'vuelidate/lib/validators'
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'FormPostInput',
  components: { Editor },
  props: {
    value: {
      type: String
    },
    label: {
      type: String,
      default: 'Enter a value'
    },
    placeholder: {
      type: String,
      default: 'Enter your text...'
    },
    required: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: 'Please enter a value'
    },
    submitted: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
  },
  validations () {
    return {
      value: {
        requiredIf: requiredIf(function () {
          return this.required
        }),
        maxLength: maxLength(this.limit)
      }
    }
  },
  data () {
    return {
      tinymce: {
        id: 'peep-message',
        inline: true,
        convert_unsafe_embeds: false,
        extended_valid_elements: 'span[class|data-text|alt|style]',
        toolbar_mode: 'sliding',
        plugins: 'emoticons',
        emoticons_database: 'emojiimages',
        emoticons_images_url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/15.1.0/72x72',
        content_style: 'body { font-size: 20px; font-weight: 200; line-height: 30px; color: rgb(5, 5, 5); margin: 6px;} .emoji-type-image { width: 28px; height: 28px; display: inline-block; background-size: 6100%; background-image: url("https://unpkg.com/emoji-datasource-apple@14.0.0/img/apple/sheets-256/64.png") }',
        style_formats: [
          'span'
        ],
        force_br_newlines: false,
        force_p_newlines: false,
        forced_root_block: '',
        toolbar: false,
        menubar: false,
        statusbar: false,
        height: '50',
        tinycomments_mode: 'embedded',
        tinycomments_author: 'Author name',
        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject(new Error('See docs to implement AI Assistant')))
      },
      editorOptions: {
        modules: {
          toolbar: false
          // short_name_emoji: true
        }
      }
    }
  },
  computed: {
    error () {
      return !this.$v.value.requiredIf && this.submitted
    },
    inputText: {
      get () {
        return this.value
      },
      set (inputText) { this.$emit('input', inputText) }
    }
  },
  methods: {
    resize () {
      const element = this.$refs['postarea']
      element.style.height = '18px'
      element.style.height = element.scrollHeight + 'px'
      if (element.scrollHeight > 100) {
        element.style.fontSize = '15px'
        element.style.lineHeight = '16px'
      }
    }
  }
}
</script>
<style lang="scss">
  .post-text-area {
    width: 100%;
    min-height: 69px;
    padding: 2px;
    resize: none;
    overflow: hidden;
    color: rgb(5, 5, 5);
    font-size: 24px;
    font-weight: 400;

    &:focus {
      outline: none;
    }
  }
</style>
