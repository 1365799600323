<template>
  <div>
    <form class="post-text ml-3 w-100">
      <AddPostStatusModal/>
      <b-form-group class="mr-4">
        <input
          type="text"
          placeholder="Ask a question!"
          class="form-control add-post-text"
          v-model="poll.question"
          style="border:none;"
        />
        <div class="d-flex justify-content-between">
          <div class="invalid-feedback d-block" v-if="!$v.poll.question.required && submitted">
            Please type a question!
          </div>
        </div>
      </b-form-group>
      <div class="mr-4 choises-area">
        <div class="p-2">
          <div v-for="(v, index) in $v.poll.options.$each.$iter" :key="index" class="mb-2">
            <div class="d-flex justify-content-between align-items-center">
              <input
                type="text"
                :placeholder="choisePlaceholder(index)"
                class="form-control regular-input choise-input mb-2 btn-text"
                limit="25"
                v-model="v.value.$model"
              />
              <div v-if="index > 1" class="ml-2 mb-2 cursor-pointer" @click="removeOption(index)"><i class="ri-close-circle-line"></i></div>
            </div>
            <div class="invalid-feedback d-block"  v-if="!v.value.required && submitted">
              <span v-if="index > 1">Please type a value or remove the option!</span>
              <span v-else>Please type a value!</span>
            </div>
          </div>
          <b-button v-if="poll.options.length < 6" class="w-100" @click="addOption()"><i class="ri-add-circle-line"></i></b-button>
        </div>
        <hr/>
        <div class="p-2">
          <b-row class="property-form">
            <b-col md="6">
              <FormSelect
                v-model="poll.days"
                label="Days"
                error-message="Please select a duration"
                :submitted="submitted"
                :options="optionsDays"
                @change="daysUpdated"
                :required="!$v.poll.days.requiredIf && submitted"
              />
            </b-col>
            <b-col md="3">
              <FormSelect
                v-model="poll.hours"
                label="Hours"
                error-message="Please select number of hours"
                :submitted="submitted"
                :options="optionsHours"
                :disabled="daySelected"
              />
            </b-col>
            <b-col md="3">
              <FormSelect
                v-model="poll.minutes"
                label="Minutes"
                error-message="Please select number of minutes"
                :submitted="submitted"
                :options="optionsMinutes"
                :disabled="daySelected"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </form>
    <div class="d-flex justify-content-center mt-2">
      <img class="img-fluid cursor-pointer" src="@/assets/images/buttons/remove-poll.svg" title="Remove Poll" alt="Remove Poll" @click="removePoll" />
    </div>
    <button class="btn btn-primary d-block w-100 mt-3" @click="addNewPoll">Tweet</button>
  </div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import FormSelect from '@/components/homeiz/common/form/FormSelect'
import AddPostStatusModal from '@/components/homeiz/feed/AddPostStatusModal'
import * as faker from 'faker'

export default {
  name: 'AddPoll',
  components: {
    FormSelect,
    AddPostStatusModal
  },
  validations: {
    poll: {
      question: { required },
      options: {
        $each: {
          value: { required }
        }
      },
      days: {
        requiredIf: requiredIf(function () {
          return !this.poll.hours && !this.poll.minutes
        })
      }
    }
  },
  data () {
    return {
      submitted: false,
      poll: {
        question: null,
        options: [{ id: 1, value: '', votes: 0 }, { id: 2, value: '', votes: 0 }],
        days: null,
        hours: null,
        minutes: null
      }
    }
  },
  computed: {
    optionsDays () {
      return [
        { value: 0, text: '0' },
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
        { value: 6, text: '6' },
        { value: 7, text: '7' }
      ]
    },
    optionsMinutes () {
      let end = 59
      const options = []

      for (end; end >= 0; end--) {
        options.push({ value: end, text: end })
      }

      return options.reverse()
    },
    optionsHours () {
      let end = 23
      const options = []

      for (end; end >= 0; end--) {
        options.push({ value: end, text: end })
      }

      return options.reverse()
    },
    daySelected () {
      // return false
      return this.poll.days >= 7
    }
  },
  methods: {
    addOption () {
      this.poll.options.push({ id: faker.datatype.uuid(), value: '', votes: 0 })
    },
    daysUpdated () {
      this.poll.hours = this.poll.days ? null : 1
      this.poll.minutes = this.poll.days ? null : 0
    },
    addNewPoll () {
      try {
        this.$v.$touch()
        this.submitted = true
        if (!this.$v.poll.$invalid) {
          this.$emit('createPoll', this.poll)
        } else {
        }
      } catch (e) {

      }
    },
    removePoll () {
      this.$bvModal.hide('poll-modal')
    },
    removeOption (index) {
      this.poll.options.splice(index, 1)
    },
    choisePlaceholder (index) {
      const i = +index + 1
      return index < 2 ? `Choice ${i}` : `Choice ${i} (Optional)`
    }
  }
}
</script>

<style lang="scss">
.choises-area {
  border: 1px solid rgb(239, 243, 243);
  border-radius: 15px;
}

.choise-input {
  color: var(--iq-martin-blue-text) !important;
  &::placeholder {
    color: var(--iq-martin-blue-text);
  }

}
</style>
