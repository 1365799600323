<template>
   <div>
    <b-form-group>
      <label>{{ label }} <span v-if="required">*</span></label>
      <b-form-datepicker
        v-model="inputText"
        class="datepicker-traditional"
        :state="!error"
        reset-button
      />
      <div class="d-flex justify-content-between">
        <div class="invalid-feedback d-block" v-if="error">
          {{ errorMessage }}
        </div>
      </div>
      </b-form-group>
    </div>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'
export default {
  name: 'FormDate',
  props: {
    value: {
      type: String
    },
    label: {
      type: String,
      default: 'Enter a date'
    },
    required: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: 'Please enter a date'
    },
    submitted: {
      type: Boolean,
      default: false
    }
  },
  validations () {
    return {
      value: {
        requiredIf: requiredIf(function () {
          return this.required
        })
      }
    }
  },
  data () {
    return { }
  },
  computed: {
    error () {
      return !this.$v.value.requiredIf && this.submitted
    },
    inputText: {
      get () {
        return this.value
      },
      set (inputText) { this.$emit('input', inputText) }
    }
  }
}
</script>
