<template>
  <b-modal id="user-followers-details" v-if="user" size="md" no-fade scrollable hide-footer>
    <template #modal-title>
      <div class="user-post-data">
        <div class="d-flex flex-wrap">
          <div class="media-support-user-img mr-3">
            <b-img rounded="circle" fluid :src="user.avatar" :alt="user.fullName"/>
          </div>
          <div class="d-flex flex-wrap justify-content-left">
            <div class="w-100 text-left full-name">
              {{user.fullName}}
            </div>
            <div class="w-100 username text-left">@{{ user.username }}</div>
          </div>
        </div>
      </div>
      <tab-nav :pills="true" id="pills-tab-followers" class="w-100 ml-3 d-flex align-items-center justify-content-between">
        <tab-nav-items
          v-for="(tab, index) in tabs"
          :key="index"
          :active="activeTab === index"
          :id="`user-followers-${index}`"
          :href="`#${tab.link}`"
          :ariaControls="tab.link"
          role="tab"
          :ariaSelected="activeTab === index"
        >
          <template v-slot:title>
            {{ tab.title }}
          </template>
        </tab-nav-items>
    </tab-nav>
  </template>
  <div>
    <tab-content class="mt-4">
      <tab-content-item
        v-for="(tab, index) in tabs"
        :key="index"
        :active="activeTab === index"
        :id="tab.link"
      >
       <UserList />
      </tab-content-item>
    </tab-content>
  </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import * as getters from '@/store/modules/user-followers/types/getters'
import UserList from '@/components/homeiz/user/follow/UserList'

export default {
  name: 'UserFollowersModal',
  components: {
    UserList
  },
  computed: {
    ...mapGetters('userFollowers', {
      user: getters.GET_USER,
      activeTab: getters.GET_ACTIVE_TAB
    }),
    tabs () {
      return [
        {
          classname: 'nav-link active',
          headerName: 'Posts from people you follow',
          link: 'followers-you-know',
          title: 'Followers you know'
        },
        {
          classname: 'nav-link',
          headerName: 'Posts from random users',
          link: 'followers',
          title: 'Followers'
        },
        {
          classname: 'nav-link',
          headerName: 'Posts from random users',
          link: 'following',
          title: 'Following'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.full-name {
  font-size: 16px;
  line-height: 16px !important;
}

.username {
  font-size: 14px;
}
</style>

<style lang="scss">
#user-followers-details {
  .modal-title {
    margin-left: 0 !important;
  }

  #pills-tab-followers {
    &.nav-pills {
      margin-top: 20px;
      .nav-link {
        font-size: 14px !important;
        border-radius: 0;
        border-bottom: 4px solid transparent;

        &.active {
          background-color: transparent;
          border-bottom-color: rgb(113, 187, 255);
        }
      }
    }
  }
}
</style>
