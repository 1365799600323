<template>
  <main>
    <div class="rounded p-2 pointer cursor-pointer text-center" title="Tag People">
      <img :src="renderIco('/icon/tag-people.svg')" alt="Tag People" class="img-fluid svg"
        @click="openModal('tag-people-modal')">
    </div>
    <b-modal id="tag-people-modal" title="Tag People" hide-footer content-class="rounded" no-fade scrollable>
      <div>
        <div class="d-flex justify-content-between mb-4">
          <form action="#" class="searchbox search-box-user d-flex position-relative align-items-center w-85">
            <input v-model="tagSearch" type="text" class="form-control router-link-active search-input"
              placeholder="Search for contacts...">
            <div class="comment-attagement d-flex">
              <b-link href="javascript:void(0);">
                <i class="ri-search-line mr-2"></i>
              </b-link>
            </div>
          </form>
          <b-button variant="facebook" class="elliptical" @click="hideTagPeople">Done</b-button>
        </div>
        <div v-if="taggedPeople.length">
          <h6 class="text-uppercase">Tagged</h6>
          <div class="d-flex flex-wrap tagged-scrollable">
            <div class="tag-people-wrapper mr-2 mb-2" v-for="(item, index) in taggedPeople" :key="index">
              <div>{{ item.fullName }}</div>
              <div class="ml-2 cursor-pointer" @click="removeTaged(item.id)">X</div>
            </div>
          </div>
        </div>
        <div>
          <h6 class="text-uppercase">Suggestions</h6>
        </div>
        <div v-for="(item, index) in filteredTagItems" :key="index" :id="`popover-user-${item.id}`"
          class="media cursor-pointer align-items-center mb-4" @click="tagPeople(item)">
          <div class="iq-profile-avatar status-online">
            <img class="rounded-circle avatar-50" :src="item.avatar" :alt="item.fullName">
          </div>
          <div class="media-body ml-3">
            <h6 class="mb-0">{{ item.fullName }}</h6>
            <p class="mb-0">{{ item.time }}</p>
          </div>
          <b-popover custom-class="user-info-popover" :target="`popover-user-${item.id}`" placement="left"
            triggers="hover focus">
            <UserPopover :user="item" />
          </b-popover>
        </div>
      </div>
    </b-modal>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import * as userGetters from '@/store/modules/chat-panel/types/getters'
export default {
  name: 'TagPeoplePopUp',
  props: {
    taggedPeople: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      tagSearch: ''
    }
  },
  computed: {
    ...mapGetters('chatPanel', {
      tagUsers: userGetters.GET_BUSINESS_USERS
    }),
    filteredTagItems () {
      return Object.values(this.tagUsers).filter(item => {
        return item.fullName.toLowerCase().includes(this.tagSearch.toLowerCase())
      })
    }
  },
  methods: {
    renderIco (ico) {
      return require(`@/assets/images${ico}`)
    },
    openModal (name) {
      this.$bvModal.show(name)
    },
    hideTagPeople () {
      this.$bvModal.hide('tag-people-modal')
    },
    tagPeople (user) {
      this.taggedPeople.push(user)
    },
    removeTaged (id) {
      this.taggedPeople = this.taggedPeople.filter(function (item) {
        return item.id !== id
      })
    }
  }
}
</script>
