<template>
  <div>
    <div v-if="!hideVids">
    <iq-card  id="post-ibuyer-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height" headerClass="vids-header" footerClass=" bg-white">
      <template v-slot:headerTitle>
        <div class="d-flex">
          <h4 class="card-title position-relative">
            <img src="@/assets/images/icon/menu/vids.svg" alt="video" class="img-fluid svg">
            <VidsLogo>Short videos</VidsLogo>
          </h4>
          <div class="iq-card-header-toolbar d-flex justify-content-end w-100">
            <b-dropdown id="dropdownMenuButton40" right variant="erez007" toggle-class="pl-0 post-action-btn" menu-class="p-0">
              <template v-slot:button-content>
                <b-link href="javascript:void(0)">
                  <i class="m-0 ri-more-line font-size-16"></i>
                </b-link>
              </template>
              <a href="javascript:void(0)" class="dropdown-item p-2" @click="hide">
                <div class="d-flex align-items-center">
                  <div class="icon font-size-20">
                    <i class="ri-close-circle-line"></i>
                  </div>
                  <div class="data ml-2">
                    <div>Hide vids</div>
                  </div>
                </div>
              </a>
            </b-dropdown>
          </div>
        </div>
      </template>
      <div class="iq-card-body position-relative">
        <b-row>
          <b-col sm="4" v-for="(item, index) in vidiotsItems" :key="index">
            <VidiotsItem :item="item"/>
          </b-col>
        </b-row>
        <button class="vidiots-button-next">
          <i class="ri-arrow-right-s-line"></i>
        </button>
      </div>
      <template v-slot:footer>
        <div @click="$bvModal.show(`files-modal-vids`)" class="btn btn-white d-block w-100 bigger-text d-flex align-items-center justify-content-center">
          <img src="@/assets/images/icon/menu/creare-vids.svg" alt="video" class="img-fluid svg">
          <div class="ml-2">{{ title }}</div>
        </div>
      </template>
    </iq-card>
  </div>
  <div v-else>
    <div class="iq-card p-2">
      <div class="d-flex justify-content-between align-items-center m-2">
        <div class="text-bold">Vids hidden</div>
        <b-button variant="facebook" @click="hide">Undo</b-button>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import VidsLogo from '@/components/homeiz/common/VidsLogo'
import VidiotsItem from '@/components/homeiz/feed/vidiots/VidiotsItem'

export default {
  name: 'Vidiots',
  components: {
    VidiotsItem,
    VidsLogo
  },
  data () {
    return {
      title: 'Create vids',
      hideVids: false,
      vidiotsItems: {
        1: {
          id: 1,
          image: require('@/assets/images/vidiots/278505004_1191477094928713_8344911923923429406_n.jpg'),
          avatar: require('@/assets/images/user/erez.jpeg')
        },
        2: {
          id: 2,
          image: require('@/assets/images/vidiots/278505004_1191477094928713_8344911923923429406_n.jpg'),
          avatar: require('@/assets/images/user/erez.jpeg')
        },
        3: {
          id: 3,
          image: require('@/assets/images/vidiots/278505004_1191477094928713_8344911923923429406_n.jpg'),
          avatar: require('@/assets/images/user/erez.jpeg')
        }
      }
    }
  },
  methods: {
    hide () {
      this.hideVids = !this.hideVids
    }
  }
}
</script>

<style scoped lang="scss">
  .absolute-icon {
    position: absolute;
    left: 10px;
  }

  button {
    &.vidiots-button-next {
      position: absolute;
      top: 40%;
      width: 40px;
      height: 40px;
      background: var(--iq-primary);
      color: var(--iq-white);
      border-radius: 50%;
      overflow: hidden;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      opacity: 1;
      transition: all 0.5s ease-out 0s;
      cursor: pointer;
      right: 19px;

      &:hover {
        background: var(--iq-dark-primary);
      }
    }
  }
</style>
