<template>
  <main v-if="pagination.loadMore">
    <b-button :size="size" :variant="variant" @click="onClickHandler">
      {{ text }}
    </b-button>
  </main>
</template>

<script>
export default {
  name: 'LoadMoreButton',
  props: {
    size: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      default: 'primary'
    },
    text: {
      type: String,
      default: 'Load more'
    },
    pagination: {
      type: Object,
      required: true
    }
  },
  methods: {
    onClickHandler () {
      this.$emit('click')
    }
  }
}
</script>
