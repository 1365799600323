<template>
  <div>
    <div v-for="(item, index) in users" :key="index" :id="`popover-follow-user-${item.id}`"
      class="d-flex align-items-center justify-content-between mb-2 pb-2">
      <div class="d-inline-block">
        <div class="media-height iq-chat-data-block active-block">
          <div class="media cursor-pointer align-items-center mb-4">
            <div class="iq-profile-avatar status-online">
              <img class="rounded-circle avatar-50" :src="item.avatar" :alt="item.fullName">
            </div>
            <div class="media-body ml-3">
              <h6 class="mb-0">{{ item.fullName }}</h6>
              <p class="mb-0">@{{ item.username }}</p>
            </div>
          </div>
        </div>
      </div>
      <FollowButton v-model="item.isFollow" class="mb-2 ml-1 w-30"/>
      <!-- <b-button variant="erez008" class="elliptical d-block w-20">Follow</b-button> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as getters from '@/store/modules/chat-panel/types/getters'
import FollowButton from '@/components/homeiz/common/buttons/FollowButton'

export default {
  name: 'UserFollowers',
  components: {
    FollowButton
  },
  mounted () {
    console.log(this.users)
  },
  computed: {
    ...mapGetters('chatPanel', {
      users: getters.GET_BUSINESS_USERS
    })
  }
}
</script>
