<template>
  <div>
    <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height" class="mb-0">
      <div class="iq-card-body p-0">
        <div class="d-flex align-items-center pl-3 pr-3 pt-3 pb-4" v-b-modal.post-modal>
          <div class="user-img avatar-40">
            <img class="avatar-50 rounded-circle" :src="postAuthor.avatar" :alt="postAuthor">
          </div>
          <form class="post-text ml-2 w-100">
            <input
              type="text"
              :placeholder="placeholderText"
              class="form-control add-post-text cursor-pointer"
              v-model="post.description"
              style="border:none;"
            />
          </form>
        </div>
        <ul class="post-opt-block d-flex align-items-center justify-content-between list-inline m-0 pl-3 pr-3 pb-0 pt-4 text-royal-blue">
          <li class="rounded p-2 pointer d-flex align-items-center cursor-pointer mr-3" title="Photo" v-b-modal.post-modal>
            <img src="@/assets/images/icon/photo.svg" alt="Photo" class="img-fluid svg">
          </li>
          <li class="rounded p-2 pointer d-flex align-items-center cursor-pointer mr-3" title="Vids" v-b-modal.post-modal>
            <img src="@/assets/images/icon/vids.svg" alt="Vids" class="img-fluid svg">
          </li>
          <li class="rounded p-2 pointer d-flex align-items-center cursor-pointer mr-3" title="Poll" v-b-modal.poll-modal>
            <img src="@/assets/images/icon/poll.svg" alt="Poll" class="img-fluid svg" >
          </li>
          <li class="rounded p-2 pointer d-flex align-items-center cursor-pointer" title="Schedule" v-b-modal.schedule-modal>
            <img src="@/assets/images/icon/schedule.svg" alt="Schedule" class="img-fluid svg">
          </li>
        </ul>
      </div>

      <b-modal
        id="post-modal"
        title="Create Tweet"
        size="md"
        scrollable
        content-class="rounded"
        no-fade
        no-close-on-backdrop
        no-close-on-esc
        @close="onCloseHandler"
      >
        <div class="d-flex">
          <div class="d-flex mr-2">
            <div class="media-support-user-img mr-3">
              <b-img rounded="circle" fluid :src="authUser.avatar" :alt="authUser.fullName" />
            </div>
            <div class="media-support-info">
              <h5 class="mb-0 d-flex align-items-center">
                {{ authUser.fullName }}
                <span class="user-location d-flex align-items-center">
                  <div v-if="authUser.isBusinessOwner" title="Business account">
                    <img src="@/assets/images/icon/business-owner.svg" class="img-fluid svg-30" />
                  </div>
                </span>
              </h5>
              <h6>{{ authUser.role }}</h6>
            </div>
          </div>
          <div class="d-flex">
            <div class="iq-card-post-toolbar">
              <AddPostStatusModal/>
            </div>
          </div>
        </div>

        <div class="d-flex">
          <tag-people-presenter :taggedPeople="post.taggedPeople" />
        </div>

        <div class="mt-4">
          <form class="post-text w-100">
            <form-post-input
              v-model="post.description"
              :placeholder="placeholderText"
            />
          </form>
        </div>

        <div class="other-option d-flex justify-content-end">
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <v-progress-circular
                :value="percentsLeft"
                :rotate="360"
                :color="limitColor"
                :width="1"
              >
                {{ symbolsLeft }}
              </v-progress-circular>
          </div>
          </div>
        </div>
        <div v-if="post.files.length" class="d-flex w-100 mt-2">
          <PostFiles :files="post.files" />
        </div>
        <div v-if="post.videos.length" class="d-flex w-100 mt-2">
          <PostVideos v-if="videoLoaded" :videos="post.videos" />
        </div>
        <div v-if="post.images.length" class="d-flex w-100 position-relative mt-2">
          <PostPhotos  :images="postImages" />
          <b-button class="position-absolute remove-photos" variant="primary" @click="clearPhotos">X</b-button>
        </div>
        <div class="d-flex justify-content-between align-item-end mt-4">
          <div class="mr-2 hashtag-action">+</div>
          <div class="mr-2 hashtag-action">#</div>
          <div class="tweet-alert-message">
            Vast exposure potential. If your short vid is well-received and relevant to a particular hashtag,
            it has an increased chance of going viral on the trending page and reaching a large audience,
            improved engagement that can lead to increased engagement from users who are interested in the same topics.
          </div>
        </div>
        <div v-if="emojiWindow" class="mt-2 d-flex justify-content-center">
          <picker
                set="apple"
                :data="emojiIndex"
                :dynamicWidth="true"
                :perLine="12"
                :showPreview="false"
                @select="addEmoji"
              />
        </div>
        <template #modal-footer>
          <div class="w-100">
            <ul class="d-flex justify-content-around align-items-center list-inline m-0 p-0">
              <li>
                <FileUploader
                  :files="post.images"
                  title="Select files to begin"
                  alt="Photo"
                  subTitle="Share images in your post"
                  description="(Up to 15 Photos)"
                  accept="image/*"
                  :limit="15"
                  :size-limit="fileSizes.image"
                  @onFilesUpdate="updatePhoto"
                />
              </li>
              <li>
                <div
                  class="rounded p-2 pointer d-flex align-items-center cursor-pointer"
                  title="Editor"
                  @click="$bvModal.show(`files-modal-vids`)"
                >
                  <img src="@/assets/images/icon/vids.svg" alt="Vids" class="img-fluid svg">
                </div>
              </li>
              <li>
                <FileUploader
                  :files="post.files"
                  image="file-uploader.png"
                  icon="attachment.svg"
                  title="Select file"
                  alt="File"
                  subTitle="Accepted files PDF, DOC"
                  description="(Up to 5 files)"
                  accept="application/pdf, application/msword"
                  :limit="5"
                  :size-limit="fileSizes.document"
                  @onFilesUpdate="updateFile"
                />
              </li>
              <li>
                <tag-people-pop-up :taggedPeople="post.taggedPeople" />
              </li>
              <li v-for="(item,index) in tab" :key="index">
                <div class="rounded p-2 pointer cursor-pointer text-center" :title="item.name">
                  <a href="#"></a>
                  <img :src="renderIco(item.icon)" :alt="item.name" class="img-fluid svg">
                </div>
              </li>
              <li>
                <div class="rounded p-2 pointer cursor-pointer text-center" title="Emoji"  @click="handleEmoji">
                  <img src="@/assets/images/icon/feeling.svg" alt="Emoji" class="img-fluid svg">
                </div>
              </li>
            </ul>
            <button class="btn btn-primary d-block w-100 mt-3" :disabled="disableButton" @click="addNewPost(post)">Tweet</button>
          </div>
        </template>
      </b-modal>

      <b-modal id="poll-modal" title="Create Poll" header-close-content="" hide-footer content-class="rounded" no-fade>
        <div>
          <AddPoll @createPoll="addNewPoll" />
        </div>
      </b-modal>

      <b-modal id="schedule-modal" size="md" title="Schedule Your Tweet" hide-footer content-class="rounded" no-fade>
        <SchedulePost @schedulePost="schedulePost" />
      </b-modal>

      <b-modal id="emoji-modal" size="sm" title="Emoji" hide-footer content-class="rounded" no-fade>
      </b-modal>
    </iq-card>
  </div>
</template>

<script>
import data from 'emoji-mart-vue-fast/data/all.json'
import 'emoji-mart-vue-fast/css/emoji-mart.css'

import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'
import Post from '@/Model/Post'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import * as feedGetters from '@/store/modules/feed/types/getters'
import { MAX_DOCUMENT_FILE_SIZE, MAX_VIDEO_FILE_SIZE, MAX_IMAGE_FILE_SIZE } from '@/config/file.js'
import AddPostStatusModal from '@/components/homeiz/feed/AddPostStatusModal'
import AddPoll from '@/components/homeiz/feed/poll/AddPoll'
import SchedulePost from '@/components/homeiz/feed/SchedulePost'
import FileUploader from '@/components/homeiz/common/form/FileUploaderLinkedin'
import PostPhotos from '@/components/homeiz/feed/post/PostPhotos'
import PostFiles from '@/components/homeiz/feed/post/PostFiles'
import PostVideos from './post/PostVideos.vue'
import * as postType from '@/config/post/postType'
import * as systemNotificationActions from '@/store/modules/system-notification/types/actions'
import TagPeoplePresenter from './tag/TagPeoplePresenter.vue'
import TagPeoplePopUp from './tag/TagPeoplePopUp.vue'
import FormPostInput from '../common/form/FormPostInput.vue'

let emojiIndex = new EmojiIndex(data)

const COLONS_REGEX = new RegExp(
  '([^:]+)?(:[a-zA-Z0-9-_+]+:(:skin-tone-[2-6]:)?)',
  'g'
)

export default {
  name: 'AddPeep',
  components: {
    FileUploader,
    AddPostStatusModal,
    AddPoll,
    SchedulePost,
    PostPhotos,
    PostFiles,
    PostVideos,
    TagPeoplePresenter,
    TagPeoplePopUp,
    FormPostInput,
    Picker
  },
  created () {
    this.onLoad()
  },
  inject: ['authUser'],
  props: {
    author: {
      type: Object,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters('feed', {
      draftPost: feedGetters.GET_DRAFT_POST
    }),
    postAuthor () {
      return this.author === null ? this.authUser : this.author
    },
    fullName () {
      return `${this.authUser.firstName} ${this.authUser.lastName}`
    },
    placeholderText () {
      return this.placeholder ? this.placeholder : `What's new, ${this.postAuthor.firstName}?`
    },
    disableButton () {
      return !(this.post.description || this.post.images.length || this.post.files.length || this.post.videos.length)
    },
    postImages () {
      if (this.post.images.length) {
        return this.post.images.map(image => {
          return image.preview
        })
      }
      return []
    },
    symbolsLeft () {
      return this.post.description ? this.limit - Number(Number(this.post.description.length)) : this.limit
    },
    percentsLeft () {
      return this.symbolsLeft / this.limit * 100
    },
    limitColor () {
      if (this.percentsLeft < 20 && this.percentsLeft > 0) {
        return 'orange'
      } else if (this.percentsLeft <= 0) {
        return 'red'
      } else {
        return 'martin-blue'
      }
    }
  },
  data () {
    return {
      emojiWindow: false,
      emojiIndex: emojiIndex,
      fileSizes: {
        image: MAX_IMAGE_FILE_SIZE,
        video: MAX_VIDEO_FILE_SIZE,
        document: MAX_DOCUMENT_FILE_SIZE
      },
      isLoaded: false,
      videoLoaded: false,
      post: {},
      limit: 300,
      tab: [
        {
          icon: '/icon/gif.svg',
          name: ' GIF'
        }
      ]
    }
  },
  methods: {
    ...mapActions('systemNotification', {
      setDoneNotification: systemNotificationActions.SET_DONE_NOTIFICATION
    }),
    async onLoad () {
      if (this.draftPost) {
        this.post = new Post({ ...this.draftPost, user: this.authUser })
      } else {
        this.post = new Post(
          { user: this.authUser }
        )
      }
      this.isLoaded = true
    },
    async onCloseHandler (bvModalEvent) {
      if (!this.disableButton) {
        bvModalEvent.preventDefault()
        const response = await this.$bvModal.msgBoxConfirm(
          'The tweet you started will be here when you return.',
          {
            title: 'Save this tweet as a draft?',
            okTitle: 'Save as draft',
            cancelTitle: 'Discard',
            cancelVariant: 'transparent'
          })
        if (response) {
          this.$emit('saveDraft', this.post)
        } else {
          this.resetPost()
          this.$emit('discardPost')
        }
        this.$bvModal.hide('post-modal')
      }
    },
    addNewPost (post) {
      this.emojiWindow = !this.emojiWindow
      this.$emit('addPost', post)
      this.post = new Post({ user: this.authUser })
      this.$bvModal.hide('post-modal')
    },
    addNewPoll (poll) {
      this.post = new Post({
        type: postType.POLL,
        description: poll.question,
        pollOptions: poll.options,
        user: this.authUser,
        pollDuration: { days: poll.days, hours: poll.hours, minutes: poll.minutes }
      })
      this.$emit('addPost', this.post)
      this.resetPost()
      this.$bvModal.hide('poll-modal')
    },
    resetPost () {
      this.post = new Post(
        { user: this.authUser }
      )
    },
    previewImage: function (event) {
      const files = event.target.files
      Object.keys(files).forEach(i => {
        const file = files[i]
        const reader = new FileReader()
        reader.onload = (e) => {
          this.post.images.push(e.target.result)
        }
        reader.readAsDataURL(file)
      })
    },
    updatePhoto (data) {
      if (data.length) {
        this.post.images = data
        // this.post.images = data.map(image => {
        //   return image.preview
        // })
      } else {
        this.post.images = []
      }
    },
    clearPhotos () {
      this.post.images = []
    },
    updateVideo (data) {
      this.post.videos = data
      this.videoLoaded = true
    },
    updateFile (data) {
      this.post.files = data
    },
    renderIco (ico) {
      return require(`@/assets/images${ico}`)
    },
    schedulePost (form) {
      this.setDoneNotification(
        {
          header: 'It\'s scheduled.',
          text: `Your Tweet was successfully scheduled. It will be posted on ${moment(String(form.date)).format('dddd, MMMM Do')} at ${form.time.hh}:${form.time.mm} ${form.time.A}`
        }
      )
    },
    addEmoji (emoji) {
      const emojiHtmlStr = this.emojiToHtml(emoji)
      this.post.description = this.post.description ? `${this.post.description}${emojiHtmlStr}` : emojiHtmlStr
    },
    emojiToHtml (emoji) {
      let style = `background-position: ${emoji.getPosition()}`
      return `<img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class="emoji-set-apple emoji-type-image" style='${style}'/>`
    },
    wrapEmoji (text) {
      return text.replace(COLONS_REGEX, function (match, p1, p2) {
        const before = p1 || ''
        // We add "data-text='{emoji.native}'", don't replace it
        if (text.endsWith(before, 'alt="') || text.endsWith(before, 'data-text="')) {
          return match
        }

        let emoji = emojiIndex.findEmoji(p2)
        if (!emoji) {
          return match
        }
        return before + this.emojiToHtml(emoji)
      })
    },
    handleEmoji () {
      this.emojiWindow = !this.emojiWindow
    }
  }
}
</script>

<style lang="scss" scoped>
  hr {
    margin: 0.6rem 0 0.2rem 0;
  }

  .iq-card-body {
    padding-bottom: 10px !important;
  }

  .modal-dialog {
    max-width: 800px !important;
  }

  .post-icon {
    font-size: 22px;
  }

  .tweet-alert-message {
    font-size: 14px;
    padding: 10px;
    border: 1px solid var(--iq-martin-blue-text);
  }

  .hashtag-action {
    font-size: 18px;
    font-weight: bolder;
    color: var(--iq-martin-blue-text);
  }
</style>

<style lang="scss">
.v-progress-circular__info {
  font-size: 11px;
}
</style>
