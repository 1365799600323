<template>
    <main class="d-flex align-items-center mt-2 w-100 flex-wrap">
      <div class="text-bold mr-2" v-if="taggedPeople.length">Erez Jonathan is with </div>
      <div class="tag-people-wrapper mr-2 mb-2" v-for="(item, index) in taggedPeople" :key="index">
        <div>{{ item.fullName }}</div>
        <div class="ml-2 cursor-pointer" @click="removeTaged(item.id)">X</div>
      </div>
    </main>
</template>

<script>
export default {
  name: 'TagPeoplePopUp',
  props: {
    taggedPeople: {
      type: Array,
      required: true
    }
  },
  methods: {
    removeTaged (id) {
      this.taggedPeople = this.taggedPeople.filter(function (item) {
        return item.id !== id
      })
    }
  }
}
</script>
