<template>
  <div class="position-relative">
    <img class="img-fluid vidiots" :src="item.image"/>
    <div class="media-support-user-img d-flex flex-wrap align-items-center justify-content-center mr-3 position-absolute">
      <b-img rounded="circle" fluid :src="item.avatar"/>
      <div class="text-white text-bold mt-3">Erez J</div>
      <b-button class="mt-3" variant="facebook">Follow</b-button>
    </div>
    <div class="trend">#greenscreensticker #realestate #realestateagent</div>
  </div>
</template>

<script>
export default {
  name: 'VidiotsItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .vidiots {
    border-radius: 8px;
  }
  .media-support-user-img {
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
</style>
