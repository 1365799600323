<template>
    <div>
      <iq-card body-class="iq-card iq-card-block iq-card-stretch iq-card-height" class="mb-0 border-bottom-none">
        <div class="iq-card-body pl-4 pr-4 pt-0 pb-0">
            <ul id="peep-filter-tab"
                class="nav nav-pills d-flex align-items-center text-center profile-forum-items p-0 m-0 justify-content-between">
                <li v-for="(tab, index) in tabs" :key="index" class="p-0">
                    <a
                        class="pl-0 pr-0 pb-3"
                        :class="tab.classname"
                        data-toggle="pill"
                        :href="tab.link"
                    >
                        {{ tab.title }}
                    </a>
                </li>
            </ul>
        </div>
        </iq-card>
    </div>
</template>

<script>
export default {
  name: 'PeepFilter',
  computed: {
    tabs () {
      return [
        {
          classname: 'nav-link active',
          headerName: 'Tweets from accounts you follow',
          link: '#tweets-from-follow',
          title: 'Tweets from accounts you follow'
        },
        {
          classname: 'nav-link',
          headerName: 'Tweets from random accounts',
          link: '#random-tweets',
          title: 'Tweets from random accounts'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
#peep-filter-tab {
  .nav-link {
    border-radius: 0px;
    border-bottom: 4px solid transparent;
    font-weight: bold;
    color: var(--iq-primary) !important;
    &.active {
      color: var(--iq-black) !important;
      background-color: transparent;
      border-bottom: 4px solid var(--iq-martin-blue-text);
    }
  }
}
</style>
