<template>
  <div v-if="isLoaded">
    <div to="rightSidebar">
      <PeepChat />
    </div>
    <b-row>
      <b-col sm="12">
        <b-row class="m-0 p-0">
          <b-col class="peep-feed" lg="8">
            <div class="border-left border-right">
              <b-row>
                <b-col md="12">
                  <div class="add-peep-block">
                    <PeepFilter />
                    <hr class="mt-0"/>
                    <AddPeep @addPost="addPost" />
                  </div>
                </b-col>
              </b-row>
              <b-row class="peep-feed-list">
                <b-col md="12">
                  <!-- <AddGeoPosting /> -->
                  <!-- <AddVidiots /> -->
                  <div v-for="(post, index) in itemsSorted" :key="post.id">
                    <SocialPost hide-comments :post="post"></SocialPost>
                    <div v-if="index === 6">
                      <AddVidiots />
                    </div>
                  </div>
                  <div class="col-sm-12 text-center">
                    <LoadMoreButton class="mb-3" :pagination="pagination" @click="onLoadMore" />
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col lg="3" offset-lg="1">
            <div class="position-fixed">
              <FollowWidget class="peep trends-widget mt-0" />
              <hr/>
              <TrendsWidget class="peep trends-widget mt-0" />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <UserFollowersModal />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { socialvue } from '@/config/pluginInit'
import * as feedActions from '@/store/modules/feed/types/actions'
import * as feedGetters from '@/store/modules/feed/types/getters'
import * as feedTypes from '@/config/post/feedType'
import LoadMoreButton from '@/components/homeiz/common/buttons/LoadMoreButton'
// import AddGeoPosting from '@/components/homeiz/feed/AddGeoPosting'
import SocialPost from '@/components/homeiz/feed/PeepPost'
import PeepChat from '@/components/homeiz/chatBars/PeepChat'
import AddPeep from '@/components/homeiz/feed/AddPeep'
import AddVidiots from '@/components/homeiz/feed/AddVidiots'
import PeepFilter from '@/components/homeiz/feed/peep/PeepFilter'
import TrendsWidget from '@/components/homeiz/widgets/TrendsWidget'
import FollowWidget from '@/components/homeiz/widgets/FollowWidget'
import UserFollowersModal from '@/components/homeiz/user/follow/UserFollowersModal'

export default {
  name: 'PeepFeed',
  components: {
    AddPeep,
    // AddGeoPosting,
    AddVidiots,
    SocialPost,
    PeepChat,
    TrendsWidget,
    FollowWidget,
    PeepFilter,
    UserFollowersModal,
    LoadMoreButton
  },
  async created () {
    await this.onLoad()
    if (this.createPeepStateModal) {
      this.setCreatePeepModalState(false)
      this.$bvModal.show('post-modal')
    }
  },
  mounted () {
    socialvue.index()
    socialvue.bodyClassCheck('', 'peep')
  },
  data () {
    return {
      isLoaded: false
    }
  },
  computed: {
    ...mapGetters('feed', {
      items: feedGetters.GET_POSTS,
      itemsMap: feedGetters.GET_POSTS_MAP,
      pagination: feedGetters.GET_PAGINATION,
      createPeepStateModal: feedGetters.GET_CREATE_PEEP_MODAL_STATE
    }),
    itemsSorted () {
      return this.itemsMap.map(id => this.items[id])
    }
  },
  methods: {
    ...mapActions('feed', {
      clearItems: feedActions.CLEAR_POSTS,
      fetchItems: feedActions.FETCH_POSTS,
      createPost: feedActions.CREATE_POST,
      setCreatePeepModalState: feedActions.SET_CREATE_PEEP_MODAL_STATE
    }),
    async onLoad () {
      await this.clearItems()
      await this.fetchItems({
        page: 1,
        type: feedTypes.PEEP_FEED
      })
      this.isLoaded = true
    },
    async onLoadMore () {
      await this.fetchItems({
        page: this.pagination.currentPage + 1,
        type: feedTypes.PEEP_FEED
      })
    },
    addPost (post) {
      this.createPost(post)
    }
  }
}
</script>
