<template>
  <div>
    <b-button id="statusDropDown" class="pt-0" variant="homeiz-black--no-border" toggle-class="elliptical" v-b-modal.post-status-modal>
      <div class="text-left" v-for="(status, index) in activeStatus" :key="index">
        <img :src="renderIco(status.icon)" :alt="status.text" class="img-fluid svg mr-2"/>
        <span>{{ status.text }}</span>
        <i v-if="index === 0" class="ri-arrow-down-s-fill ml-2"></i>
      </div>
    </b-button>
    <b-modal
        size="md"
        id="post-status-modal"
        :title="title"
        scrollable
        ok-title="Save"
        ok-variant="primary"
        @ok="save"
        cancel-variant="homeiz"
        cancel-title="Back"
        content-class="rounded"
        no-fade
      >
        <div class="d-flex align-items-center status-description">
          <div class="logo-wrapper">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 403 409" xml:space="preserve">  <image id="image0" width="40" height="40" x="0" y="0"
    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZMAAAGZCAIAAADD5UtQAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
CXBIWXMAAA7EAAAOxAGVKw4bAABJVklEQVR42u3dd5xU5b0/8O9zzpm+U3baLr1JR+lSl91FsN70
301yE2O6scYCdo29VxRBYMEYYxJjvIm5ufem3ajI0gWkLVIERRDYKTu9nfN8f3/s2pCyZWbOMzPf
98tXXkSX2TMzZz7zPN+nMUQEQggpKZLeF0AIIV1GyUUIKT2UXISQ0kPJRQgpPYreF6APjsAReccf
QEMEBEViJplJTO+LI+REUirGcjyW44kcZjXMcsxxzHLIaZDjPMsxy4EjuExStVGyGyWjxIwyGCRm
lJhBYhaFWZXyubnLPLnCGR7L8WiWR7M8ksVgWj2a1GI5zHLMcsxomOOY1SDLkXO0GyWPWXabJI9Z
rjbJVoVZFeY2S7UW2W2W9X4qpOIE0logpbWm+dGUdiCa2x3J7YuouyO5D+Mq8NNNCZCY3SC5TJLT
KDmNksMo1VrlM93GUW5jrVXubZV9ltK+pVmZzYqI5fh70dx7UXV/NLcvoh5OaoG0diyltaa0cIqD
ygEAPv3iYXDclxAiYMd/AUVymqQ+NuUMpzLcZTjDaRxoVwbYlYF2xSSXz3cXEQoCbG7NbA1m3wlm
90Zy78fU/TE1nlQBASQGDIABsM7dfu0fbfzMQ3MEgzTEqYxwGUdWG0Z7jEMchiEOpbet9Fow5ZBc
4QxffSS9PZTd05Y7GFc/SKgfxNVkXAMAkADYZ97vLgUOfhxkHEEDMDCvTelbJfe1KRP9pmk1ppm1
ZpuBCoUkD9oyfMOx9Npj2c2tme2h7J5wDnK84+6VAPJYwsBPSiQAJqmvXelvlc/0Ghv7WqbVmPtX
lUyElWpyIcAHMfXNw6nXD6XfCWQ+TGitaQ3SGjAGcr7f7E9+ZftbzgEMzG9Vhlcb6npZ5vQ1z+xl
NlB5jHTdsZT2+qHU6iOZ1UdSB+Pa0YQGOQ4yA7mL37Ld0/GtjMDAaVP62pRptabZfSwze1n6VYne
lyy95Dqa0v75YeovB5Krj6QDKS2Z5aAhyKyjLV0cHSkGYGDVRmm0x9jY23zhAOs4r8lMHUlyOhkN
90Zyf9iXeO1Ack84G1cRVA5Sce/h42gIHEFmNqNcY5UaelvO6WNp6GvpbRU0wkomuRBhTzT3lwPJ
3+2NbzqW0TgCdqsPmOfLgo5aKQOPVf7SANsPRtjHe40OI/UiyQm0prX1RzN/2p/4y/vJIwkNOOp/
D3/WJxUSBjJjZ/qMPxhh/+aQql7i5VdpJNfaY5nX3kv8cX/i3UC2o/MPIMqb3a79LefosCoX9rde
PKxqWq3ZbaL8Ih3ebcutOpJ+dW/8Hx+m1ZwGEhMosL6oPRU4AoOGPpaLR9i/PshabRIov0RPrrc+
Sv/ne4lX9sUPtakgAYjfF+MIGlrNyjn9LN8YbP3aYJuDqviV7cOE9tLu6Mt7k5uPpUFDUCRxA+uL
EEDloEhfHmS9ZIT9KwOsihglXXGTq/lI+uU98T/tTx6M5AAASmsSHUdQ0WiSLhpou2y049x+Fr0v
iOggreEf30s+uz3SfDgFGoAicCPr1DiAyp1VykUDrD8cYZ/TV//7WcTkaglnF++Ivvpe4nBUBYQi
jbMUAkfg4LfJPx7tuGy0o4SGnEnPbTyWeXhz29/fT0bTWscIUqnTEBD8duWS4VWXjXYOceh5P4uV
XPEcvvpe/JHNbTsD2dLOrE8gAEcmsQl+043jXF8aaLWUVuORdN3RlLZoW3TFrtiH0RxAKZQ4Ou/j
IakzfaZHprnP6WvRaz6QQMm1JZB9eFPbq/sTuRzXc3i4EBBAQ6tJ+tYQ28/HOsd5TXpfECkIRHh1
f/ypLZHmjzKAWG638afPE0Dj1TblkmFVPz/LNViPxpcQydWW5U07o0u2R/eGsyCXVP2ySxAgy0fV
mB6b4bmgv1XvqyF5FsnyZ7ZFH9sUjqS0EivDdw9HUHFKP8tdk6vn9LEqxR2I0j+5NrZmHtrU9uq+
eEcJs+zleK3DcOfk6h8Mt5sr4flWhncCmYe3RH77bqyjylEhEEDDarP0nZH2uyZVe4u4MYGeyZXW
cNnO6IJt0X2BLMgFWK8jLA3NBvaDkY7bJrr6luBiV3Kc3+2N37k+vDuYLciyM/GpCIj/PsL+2HRP
0YahdEuuQwnt1rWhl/fEMjksh0p8V2kIAA39LE/O8I7zGvW+GtJNkSxfsDXy+JZINKmV8KSHnuMI
GswZZH1yhmeMuxj3sz7JtSWYuWl16O/vJ4FV5HdUO47AcbTP9NRMrwgTZEhXHUlq1zcHf7cnjhwr
qId4MgjAcYDL8Ng0z/8bYiv0b9Mhuf72Yerala27grlKbGp9kYr9XYanZnq+NqjgbzbJo9aUdvWq
4Mvvxjo2oiHQUfZSDOzRGZ6fjnDYDAX8eBc1uTjCS3viN6wKHI2pQGuSP6FiL7vyyHTPxcOq9L4U
0imtaX5tc+A3u2IAFdxpOBkNAeCKsc57z3YXbulu8ZKLIyzYFrl9dTCZ5UBL+Y6jYbVZvm1y9byx
Tr0vhZxGIK3NWx38VUusC9uTVhqOwOGS0fZHp3n8hdk2unjJtWhH9MZVwUT7xmnkizQ0MbhuYvX9
U9z0LS6scIbfsCa4fEeUYus0OAKHb4+wPzXTU1OA8CpScj3/buzqlYFEhmLrlDRkHK6f5Lp/qttE
6SWeWI7PXx1auiPaMT+enBpHQPjuSPuCGR5Pvqd6FSO5Xt6buOyNY20pXhETTXuII2h4xTjXA1Pd
TioFiiSewxvXBhZviwJSSb7TOADgJSMdT8/05Pd+Lnhy/fWD5A/+cexoQqXaVmdxAI7fGmF/bJq7
L20vIYZ4jt+yLrxwa1vHGTyk8zgCwE9GOx6bns/wKmyabA1mL3u99WicYqsrJACZvbwzeumbgf1R
Ve+rIZBU8Y4N4We3Rii2ukNiALB8e/TBTWH1tMdEdv5RC3fBB2K5n7zR+n5bliZAdBkDMEj/uy9x
xcrAPgovXWU1vHND+Ol3Iki1rW6TGCI8vS36p/3JvD1kgS41mObzm0MbDqXAKNDe1aWEAcjsr+8l
rlrZSuGlFw3xzg2hJ7e0cY1iq2cUlkrze98O7wxl8/J4BUmuLIe7N4Zf3RsHmVpbPSABKOyv+5NX
rWx9r32POlJEOY53rm97dHNE02hxTz4obOvRzG3rwsdSWs8frCDJ8pvdsUXbI8AKXUarAO0tr/3J
K1YGDlDLq4iyGt77dtvDm8MUW/kksz/tjT+6JaL1uN6V/2jZGc4+sqVNU6l1nScMQGZ/25+89M3W
AzFqeRVDjuMDm9oe2hhWVYqtvGIAEizdEX11X6KHj5Tn5Aqm+R3rwy2tWYqtfGIAMvxjf/LSNwN7
IxRehaUhPLi57cG3wznaAaIQJBZNqXdvDL8b7lHBK8/JtWhH9E97E2W7/baOGAOF/eO9xBUrA3so
vArpgbfD928IZ6nTUDiytLM1c+fGcCLX/U5jPpNrY2tm+c4o5yhoeQs7ZqiDhqAiqAg5Djne8Wft
43/035f/JBiAIv1jf/LKlYE9bRRe+YcA970dvndjOCt+J/G4m7n9Tv7sP5/c0ly8W5oBMHhtf/KV
9+Ldf4x8zaHPcvzZm4Ffbo2INXuLY/uUdAAAg1RlZEaZGSVmkJhRYgaZMYAcxyzHnIY5DlmO0SyH
LO/Y8lDAxiMCaDhnoHXxLO8ZToPeV1M+VI4PbWm7d31bVhV4dS3/+MtVZhaTZFWYSWZmmZll6bOr
vzlCWuMZFTMc0xomMhxUFO6WzuEon/GluTXd2xM4b8n1zw9T3/rb0VBKE+JdRwAVAdBikWssst8s
96lSRlQbRrkNXpPsNEkuo1xtkqpNssygLauFM7wtw9uyPJzh24LZrcHM+zH1cFJrTajQ/vUrwpP6
7LPT8JwB1qUN3sEOCq88yHF4ZEvbvetDGTFbWwjtrSerVR5oN/SyyiPchrEeUz+b7LfKNRa51qp8
9qqzGhxNqa0pLZjWDie1bcFsSzh3OKF+mNACcRUQhTiaCAFU/r0xzuUN3m4c2pif5Irl+Df/fuyv
++L6r/LhCCqCIo32GKf1Mtf1Mg9zKkMcBl8X99k4EFN3hrM7Q7m3jqTWH8keiWaBMYE2cUUAjZ8z
wLakwTuEwqtnNIRHtrTdsz6UzokXW9ixUd8gl2FKrfmiAdZJPtMIl6Eb9+H7MXVbKPuvD1NvHEpv
DmQgx8Ggd35xrDLIixq8Fw+r6uqF5Ce5fvVu7CdvBHIa6vlCcABEj02u72WZ288ys5c5Lzv5x3N8
UyD7+oep/zuUaj6S5jkuyomQiKBi40Dr4lne4S46g6ObEODhzW13Cxhb7ZklsbNrTP9+hm1Wb8t4
rzEvB0q/25Zr/ij9p/3x/3o/Bare97PKh3tN/3NRr64eN5uH5Dqa0i787yObjqR1e+M5AoLVJH13
aNVlYxyD7IbqAuwh+2FC3RLILtsZ/fOBJAgyOxEBVN440Lp4lm+4i1pe3fHIlrY714kXWxqCxCb4
jD8e6bhwgHWgPf9bhgTS2v+8n3xhV+xfh9LA9TuOGwE4PjDNc8tEV5f+Xh6Sa8HWyLzVIY3r0eBC
AI6KxGb0Ns8b55rb11Los1cjWf7kO22Pbokk01yIziMCqDh7oHXRLA+1vLqEIzz+TuSO9cFMVqTY
QgCOdrN8w3jXz0bZC7QV8ieCaf67PbHndsa2t2YAdDrjluNIt/HvX+rVpT2deppcBxPqBf99ZMfR
jA67BiKAyod5TT8bbf/eMHtXK1k98ft98Yc3RzYdSQtx6hoCaNjY3/Jcg28YjTZ2jor45DuRO9eH
U1mRRhI1BIAZfS03jnN9eaC1aL92d1vu1rXBv+xPZnTpTCAwgIemu28c5+r8X5LvuuuunvzSp7dG
/7g3zou/JzcC5PiUfpYVjb5vDK6yFXdkYLTbWN/bEsnxbYEscL3DiwFI7EA4ty2cm9nL7C7iCekl
SkN46p3o3evCSaFORVDRqLDvj7I/XeeZ4jcX8zd7zPKFA6x+m/JOKBdLa8W+nxmAhsEc/8pAW1Wn
P8g9Sq59EfXW9aGj0RwoxR1SRIAcv+CMqsX1vrM8+nSRvGZ5Tl9LTMP1RzOAAhymwNj7bdmt4dy0
WrOXwuvkEOGpbW13rg0LdJgLAmjY16ncOcVzx8RqXd4+o8zO9pvOdBv/dTgVS/PifxkH0ry3VZ5a
29nI7lHi/PcHyT2hbLFjiwNwvHZS9S9n+0boWpZ2GKUHp7ivHe8C+Hiyq44YgCy9+X7y0jdbd/Zs
RVh5e2pr5BdrQ6LF1oRa86/n1FxzpsOi61kN5/azLGvw+WwK9Hwzhy6RmJrDl/clD8Y7uyFK90Mn
mOZ//SCp5nhR1/poqMjswRmewp3j1iVVBun+s923TqoGiRX7zf4iBqBIK99PXb4yQOF1Qgu2Rm5f
F0pkhCnJcwQN5w60/nqOv753UXuIJ3PhAGtTo89fJRc/vDYcS//vB53dNLX7qbMlkFl7JF3UwjxH
0HDeOOfN411FbuedglVhd092Pz7dY1EYqCKEF1v5fuqKNwMtYVrb+CmOsGBr5LZ1oWRGmDOoNFQA
vjfKsWK2f2S1QEMrXx5oXVjndZhlUHnxfqsEWpr/7YNUsnMfom4GgMrxtQOJcFwtXn8YAVT89ijH
/VPcRfqNnaZIcP1Y5+JGn9soQY7rPFWCASjszYOpy95s3U0LswEAgCMs3Ba5fW0okRYmtnLcZZLu
n+795WxfX5v+vYfj/PuQqidmeswGqagtL5ltCmZ2t3Wqu9DN5NofVf96MFXUJrfK6wfanpzhEaSZ
/0XfH25vOtfvMUuQKeI31QkxAJmtPJj68RsUXoAIz26P3r4uHBentaXiMK9p6Wz/jeOduk+qOZkf
j7DPm+BirIg1XJkdaMutPZrpzM92M7nWt2b2BLPFSy4VR/hNT8/01FqF+3b6rK8Nsv36glpnkZvZ
J8QAZLbqYPJHb1R6wX7xjujta0KxtCZMbPEJteZfz/H/+xCb3pdyGjeNd331jKribZLDAFT8x8F0
NHv6j093kivHcc2RdLGeDQBHf5Xy5HSvXhMguuT8ftZX/q3WbpKFqHnJUvMHqZ++EdiepwNXSs6i
7dGb1wSjWWFiK4eTelmaGn2T/Sa9L+X07Abp4anu4R5j8fqMMltzNH0gdvoRxu4kVyzLVx/JFK+a
o+GPRtrP728p1u/rqbl9LK9eWGszixFeClt9MHXZm5UYXot3RG9aG4ylxZgAgQA5nNDLtLjeN75b
O1LpYqjTcNUYJzCA4vQiZPZRLPf64dRpf7A7ybU7ohbvY5DjZ9aYfzTCXqRflydz+1r+8/wat1mG
rBDh1fxh6vKVFTTaiAiLd0RvXhOKp8SobSGAysfVmp6t907ylUxstfvesKpvD7MXc8biPw+mYqfr
MHYnuVZ+lMppWIxZ4wiKxL4xxDa0BDdCOLef5aXz/X6rBDlBal6py95srYQ97BFhSUvs1jWhaEqM
TiICqHxMjXlRvXdqcZf15IXTJN04wdW/2lCk6i2D9ccyR093JmN3kuv1D9NFCmCVj/abvj+8qhi/
qwDO72d94bwan0URJLxWHkz99I3yPz2oqSV66+pgmyCxBQAqH+E3L633TqspvdhqN85j/OFwO2Os
GNV6xo4ltXeCp+nVdTm5gmmtY6yq0HcFR6NB+s7QqoH20mtwfeL8/taXL6r1WMWoecnw5vuJn74Z
KOOpEkt3Rm9aHQwLFFs40mdqavBO6/SKPAExgK8PsQ2qNoJWlO9gxM2B09RZupxcWwK5SLYoky05
nOkzfW94iVW4vqixt/mVC3q5hAgvBor0xoHklW+V5+lBS3dGb1gdCgtS2wIAFYd7jEsafDNKObba
jXIZvjrIBlD4ZhcDAFh/LK2esmPX5eTa0JpO5HjBi1wcFYN08bCqXmJP4Oqkxj7mP5xX47SIEF4A
Cvvn/uTVqwL7omUVXktbYjesDkVTqhCxhQAqDvMYFjd463qVfGwBgCKxrw+29nIYijFDAuGdQPZI
8lSlri4n16bWjNqD8x07f+kuszSnT8nMhDitc/paXjm/xmeVoROz7AqLASjsb+8lr34r0JmJM+JD
gCU7ozc2B6Mprdg7l5yMyge7Dc/O8jX2Lp97eEat+bx+ZiZBEZpdsRzfEjjVZPquvc2xHN8byUER
Nm5mMMFrHFldYuPHpza3r+XX5/prq8Qo2Cvsf99LXrEy8EGn9xUR1rKd0VtWByPi1LZyvH+18bl6
75y+5RNb7S4YYLOaJMjTUYcnxVhGxTVH85dc+6NqJFv4XccQgLGG3hZZjK/PPDq3n/XF82pqbWKE
l8z+9734ZSsD75dyy2tZS/RmwWpb/VzGpkbv3L7F2465aM5wKBa5GCOMXOXr8phcrSktqRa+wYVo
VtgF/cvwjQeAOX0tv7+w1l+lCFHzktn/7k1csTKwP1qS4bWsJXpjs1gjif2cyrIyjS0AGO4yeM1y
MYr0yA4n1FPMR+1ach1NaYliJBdM8JkGdfH8tRJS18v8+wtqvTYRFmYzkNn/7EtcvSpwoNQK9sta
ovNWh9pEam31dhiea/Cd1688YwsAbAZpiNMArBilrrSGpyhldL3NVYRuDsJF/S0OY9n1FT+jvpf5
9+fXeKsMUIThjlOTABT23/sSV68KvF86Na/2CRCxpBgjiQCg8lq78ly958Iy7St8YqTLUIxd+Rgk
VDx88uHFLre5tFwR1v3gjF5mMe7HAmrsbXn5XH+tXRak5vWXfckrS6FgjwBLW6I3rQ5FksKMJOa4
r8qwuN73pYGib1zTc6M9puIUoDMaBtN5Sq5AWgOtwNNQEcxm2SLC4v7Cm93H8utza/o4FCGmSsjs
v/clLn8r8GFC6/njFc6ylujNzSGRFvegp8qwrMH71UFl3tpq179Klhgr/PAi5DgmTt4j6eKsiCwv
eP+Wo9skFfqoanGc08fy63Nr+zgU/buNDECG/9mbuHyluN3G9tgSqCSvoccmL2v0fmVQ+be22jmM
UnH2cVU5JNR8VOhVxIRajC0i3CbJKkgvoCgaeptfvqC2t0OE0UYGEvxlT/wqISepLt0ZE2skUcNq
i7ykwfe1ioktAHAZpWIcLsqYyjGh5qPNlchhUaZEQLVJ1vfUueKbUWt++byaXnZF/9FGiYHM/rIn
fs2qoFAtr2UtsflrgiKNJHKHWVrc4PvG4AqKLQCwGSRTURpdiJDJS3LFipRc6DbJZTgJ9XRm9jL/
9ryaXg5RRhv/vDd+zVuBzp/cWTjYPpLYHBRpJBGrzPLiet+3hN9LPu8UBk5DUT6eiKdYdN2FK4hn
eaIoo2DVZtliEOMGLa76XubfzPX3dSoC7KTKQGav7U1c+Vbgw4Se4fXpSKJAaxLRbpIW1/u+M7RU
d47rCZmBy1ykN+IUedOFK0hpPKNBwetcCA4DM1XG2OIXNfS2/Hquv79LkII9+6+98StXBg7pF15N
LdFbhBpJ1NBqYIsbfRcPq8TYAgCJMWtx3gs81Yega9mJRTnAiDGdz1rVV31vy2/Oq+nvFKFgDyCz
P++J6zXPa1lL9CahRhI5mhS2eLbvuxXZ2vpEkd4MBJXnafUPKY4ZtebfnV/Tz2kQILwYKNJrexI/
L3rN69nt0RtWiTSSyFGR2ZJG3yXDSn63y9KAmNPyNJ+LFM20GvNvz/X3cxkEmWH/2p74NW8FP4wX
Y5IqR3jynciNa4KRtDgjiWiU2eJ63/dLf5Pe0sAAIH+9RVJMM2rNv5nj7+cy6l/zkgAU6Y974z9f
VfCaV47jw5vDt60LJTMCxZZZZs/M8v5kJMVWEeGp5ghRcgltZi/zS3P9A6oFmCrBAGT2xz3xq94K
fFSw5UE5jg9sartrfTiVFeN4VwDQ0CjBUw3eS0c59L6UCoOQn1kRRBd1vcwvzfUPqhZltPFPu+NX
vFWQlleO410bwvdtCGdVFCW2OALA042+n1Fs6SFHyVXSZtSaXz6vdrBbhIJ9R3hd/VYgvzUvFeG2
deEH3w6rXJzYAuC4iGJLL6ecykDJVRom+02/ObdmcLVR//CSGMjsj7vj16zK3yRVhFvXhh7f3IYI
xdj7qTM4AufPNPguH02xJSJKrpIxxW96aa5/sFuA0UYJQGb/uTt27arg4R6HV5bj/LWhxza3cY6i
xJaGBo4L6n1XnenU+1LIiVFylZKpNaYX5/gHe4z67+clMVCkV9+N/3xV8Eiy++GVUvGmtaHHN4dR
nE6ihgriI7N8Pz+LYktclFwlZnqN+cVz/IM9Rv1bXgxAYa/ujl/5VvBosjs1r5SGt6wLPrW5DRDE
iS3g8Ei979qxFFtCo+QqPdNrzb+ZWzPUY4JccZZjnRwDkOE/d8evfCvwURfDK6vhzWtCC7ZEAISq
beEjs7zXUWtLeJRcJWlKjem359YM8xqLcVT6qTEGErz6bvzqVcHDnZ7nleM4b03o6XfaAMSJLQAO
D9V5bxhHsVUCKLlK1USf8aW5NcM8RkE2I3x1V/Ta5uDhTtS80ipc0xxcKFZsIeP4wAzPTeNdel8K
6RRKrhI2yWd8cY5vuNekf81LApDZK7ui160KHjlltzGW41etCizeGgUQJrY0lDW4b7r7lgkuvS+F
dBYlV2k7229+Yba/I7z07ThKDBTp9+/Gr1510oJ9MM2veiu4fIdItS0NJYS7prtvnVit96WQLqDk
KnlTakwvzPEP85n07zYyAJn94d3o1asCR1PHh9fRpHb1qsCvdra3tnS+0g4cgeMd09y3T6LYKjGC
3EGkR6b4Tb+d4x/pM4Eqwmgje2VX+zyvT8PrcEK7pjn4210xkIRpbXEEDrdN9dxFsVWCKLnKxASf
6Tdza0b5xBhtlOH3LdFrVgWOpbR4DjcFMtc2B1/eHQOp8LuBdxIH4HjLlOp7JlNslSRF7wsgeTPO
a3xxjv+Sfx7b0ZrR+bAJiQHA73fFqgxsiNOwoiW+ry0HTJzYQsbhlrPdd0+uFqT9R7qK2lxlZYLX
9MI5/jF+syAF+xU7YuuOZjMaAhbjjOFO0VDS4KbJ1fdOcSuCXBLpOkqucjPRZ3q+0Te6xgyq3uHF
ACT25/2JeI7Lghw4xhE4Xj+p+t6zq8W4INJN9PaVoUl+0wuz28NLgII9g7YM11Dv6ht0lOSvnVh9
/9RqhXqJJY6SqzxN9Jl+N9c/rtYEmt7hBUU75eqUEEDDaye6HpzqNlJslT5KrrI1xm18cY5/Ui+z
EOGlL46g4bxJ1Q9NcZsF2ZSC9AwlVzkb4zY+P9s3tXdlh5eGkga3TK5+aKq7Ys9OLz+UXGVujNu4
rNE3ra9F/4K9LjSUONw6tfqBqW6qbZUTSq7yN8ZtbGrwzuxvBZWDCJXyouEIHG+bVn3v2W69L4Xk
GSVXRRhVbWxq8NUPtFZQt5EjcLhzuueeyRRbZYiSq1IMdxmeb/DPHmgDXgHhxQE43DPNTWsSyxUl
VwUZ5FCa6r3nlX3LiyNoeO909+20cU35ouSqLIMchufqvRcNtrUfFVGGNJQ1uH+G57aJ1bS2p4zR
iuuuSap4OKEeS/FoVstwBGTtbRcGYFGg2iT3tsl9bEK/qgPthoWzvJIE/7UnASDMnjN5oaGEcN9M
z81i726a43gwrh5J8VhWS6kYTPNwhjOAflWKWQGFsV42eaDdUG2ihsVJCf0ZE0oky/96MPXPg8kN
rZndbblUhgP//CpiCVwWebzHOLefZZLfPNRpGGgX9OUdaFcWzvTKjP1pTxxAmPNZe0hD4Hhfnfdm
gfeSb2nL7mnLNX+U/ueh9K5wNtl+F8HHu2ggAgJIrNah1PWynNfPMs5nHOsx0srwLxL0oyWULYHs
awcSG45k3jqajiY1YNCxPZ7MjqsWtaW01z9IvX447TZJwxyGfxts+8HwKjGbYP3tysI6r8zg1d0x
YEyIBTo9wREQHpjpuUXU2NoSyP5qd+z1D1P7omosowEHkD++iz7Vnl9wJK6+8m7sT/sTA+3KBK/x
m2dUfX2wTe9nIBYRP1Ti+CCmvrQn/st3Y7tbs8ARDAyUz3/Ej/vAMwYSAEIopa2NqWuPZl7dl/jK
IOuVY5xes3At/z42edEsryzB73fFQWIlXPPkCAiP1HluGOfS+1JO4FhKe/HdWNOu+K5jGQAEmQFj
p/rksY4bKafyPcHsnmOZvx1M/W5v/LLRztl9zHo/G1FQcp0YR/jbweQDm9rWfJTWVASFdWF7qfY7
z8gAYfNHqc2t6c2B7OPT3EOcBr2f1vH8FvmZmV4DYy+1xABKs+alocLYI3We64Q8lXrjscw9G8N/
fT+Z07p4FwF0fJ0orC2lvdISW/VR+p6z3T8cYaclTEDJdUJZjvdubFuwNRJLa+1n0HfzgRiAQQKO
r+2NvxfJ3X129dcGCdfm91vkJ2d4FAle2CHSSWKdpKJZZo/Uea4+U7jY4gh/2Je4fX1oTyALMkBP
8kZmILGPorlrVwX2RnK3THA5jaXbQs4PSq7jxXL8/k1tD29sA8Qe3W2fkBggbDuauez11kCa/3CE
vdtJWCA+i/z4dI/M2Irt0VIq2KtoMbAn6ryXjXbofSnHi2T5gm2RBe9EQgkNlHyUERmAIiWy+PCm
tiNJ7YEp1b2FrJ8WTUU/+S9ChDs3hJ/c3AbA8hNb7RiAgR1LaNe/FdA4CvhJ85jlR6d7JAZN20ok
vDS0GNhT9b5LR9r1vpTjJVR+18bwU+13UX6/piQAhBe2R3Icl9T7qgzCv00FU+ltzuMs2hF5ZmsE
sDAvjMLiWX772tCi7VG9n+gJuE3SY9M9PznTUQLLgzS0GqWFQsZWSsOb14YWbW0/DbcAv4ABKOyV
PfGnt0X0fq56ouT61O/2xG9ZG1LVPHUST0hmwaR209rg4h0ihpfTKD0xw/OzsS6hZ9hraDdLi+t9
PxIvttoy/BfrQgu3RrNqIdutjOU0fGxL218PpvR+xrqh5OpwOKE+vS0ai2sFjK12CountJvXhJa2
RHU/GvGL7Abp0anuy8c6gX88SVIoKneY5UWzfJcMr9L7Uo6X0fDet8NPbGkD7Fk9vjNkFk5od20I
H4ipej9vfVBydXh5b2LtkTQUp3CgSNG0Nr85uHC7iA1+u1F6aJr7irEuxlGs8FLRZVOeq/dePEy4
2EqqfN7q4JPvRHiBSg1fJLN1h1MrWkRsvBcBJRcAwL5o7vldMdR48SrTMoul+H3rw8/tFLHl5TBI
D051Xz7OBeKEl4q+KrmpwfsfQ4WLrUiW37Eh/Nz2KPIiDm4wAA3/+WGqLSNsx76AKLmAI/zxveS2
o0U/F1phgaR2w1vBZS1RMbLhcxxG6eGpnivGudoPstf5alSsqZKbGn3fGCxcbMVy/J63w0+83abx
os+GU6QtrZkX3o3p/fbogJILDsXVX70b02fhnsLiOX7r6tBiIbuNVQb28FTPVeOdgKBnwV7FPg6l
abbvywOFm8eb0fDWteGFWyIdq1mLTIJUFn+1O74vktP7lSj+U694a49ltrVmCl5SPRmZhVPazWtD
zwo5VaLKwB6Z6rlqnFO3bqOK/ZzK0kbfvw0QLrbasvzWdaFnt0eymn4z4GS2NZjZ1ZbV+8UotkpP
Lo645kgaQNfTTBUWS/Hb1oaW7YwJWPOyKOzhqe5rJ7gYByjy9eX4AJdhSYP/wv5WvV+G4yVVvH9j
+MktEeSFH0k8BQZqhh+IaXq/HsVW6ckVzeGOcE7/PV4UFklp168OitnysirS/VPd10xwMSxWeCFA
jg/yGJc2eC/ob9H7BTheWsMb1wYf2xLBoo0kntLWYCYj4JdeIQnwqutq7ZHM1mBG76sAgI55Xveu
Dy3eEdW9IP5FVpndP8V9zQQXYFEK9iof4jUtb/Cd20+41lYsy29ZG1qyPQqIQnyAJLbhWOa9aGVN
7BLhhdfThtb0kaTWtb1HCkdhgaR242pBRxutCntgivvaiYUfbVRxmNe0otHX2Ee41lYky+/ZGH5q
c1jVsbZ1HIm925bbU2FF+kpfcX0wrh2/KbO+FBZP85tXBxFBwIXZFoU9OMUtATyxqQ14Yb74VBzh
NS5p8M3qJdwuehkN71gfem5bFKQu7rRVUAxSGX4kWVmlropucyHC/mgOUNfy/BcprC3Fb1obfEbI
JbVmmT0wxT1/YnX+Z9gjgIojvcYl9SLGViTLb1oTenZbNKeJ9FXXDuFwUhWwyFA4FZ1cKmIgrYm4
L4LCokl+x/rw8l0xAe9Hk8zun1J9w6RqKY+jjQig8lFe03MNvlm9hYutRI7f/3bbgq0Rru9I4skw
aE1xAW+Vwqnw5IKMsE1shUWS2g1vBV47kNT7Uk7AKLF7p1TfMLk6P6ONCKDykX7zcw1eAVtbKRVv
Xhd6dFO4UNsf5ecieUUtAhL1fSgKRBR6JyqFhZPavRvCO8IiFl+NErv37OobJ7sAetxtVPlIv2l5
o7dOvNhK5PhNa4NL2merCPxx4Ygo8L2cdwK/FQQAjNI7R9M/+NexlrCIk6QNErtnsvvmyW4A1v3w
yuGYGvPyRt+0GuFiqy3L794YfmZLJCfOSCIBAEquEmCQNh5KXfKv1hYxW14yu2dy9R2TXcC6PlUC
AXJ4Vo1paYOIsZXleNeG8JOb29ckUmyJhZKrFCjSxsOpH7/euvGYGJNmP88gsV9Mqr7rbLcisS7U
vBBA5WNrTIvrfdNqTHo/ieNFc/yG1cGFWyNqMTeuIZ1GyVUKGIAirTmc+u4/jr20J66KV4lVJHb7
RNfdZ7vNCgO1E+HVHlu15sUN3um1wsVWLMfv29j29LaoJuZIIqHkKiUy2x3KXvZ663M7RZxhLzN2
0wTXXVPcNqN0+vBS+Vm15iX1InYSEyre3j6SWKCptiQfKn0OfYlRWDyj3bUuJAFcMUa4GfYyg/nj
XDJjd68PxXMn2WD249ZWU6Nvkk+41lZK5TevCS3dKfpIIqHkKjWKFExqt6wJMoaXjXbk4wzSfJIZ
XDfWIUnwi3WhRIafoKul4oRe5qUNvonixVYowx/cFF7YfmwddRLFRl8rJUhh0TS/aU2oqSWm96Wc
gMzYNWc67p3irjJJnxttRIAcTuplWiJkbGU0vG9j+MnNkfbnoPflkNOg5CpNCoul+Lzm0JNbRVzb
KDN27ZnO+6e6bSa5Y7QRAVQ+qbdpcYOIncR4DuevDi3YGtFoJLFEUHKVLIXFUtq9G8JLdkY18UYb
GYOfn+l8aIrbbpZBRVD5pN6WxfVixha/a0Po2R0RrlFrq2RQnauUKSyc1OavCmoIl4+2i1bzAoCr
znQwhretCQ9yGZbW+8b7jHpf0fEOJdT73m57bmsEJAay3ldDOo2Sq8QpLJ7hv1gbkkDE/bwA4PLR
TossjfUaBYyt7aHstc3B//sgCZKAsU9OhZKr9CksmNBuXhNSEa4Sb6qExOBHI+16X8UJrD6SvmZV
cOOhFBgotkoP1bnKgoFF0tqta0PLWirx0NBu2BLIXtfcHluScNsEkk6g5CoXMoultHmrAguEHG0U
yvpjmR/9q3X94TQYJGptlShKrjKisFia37ch/NzOaIUdYdUFG1szP38rsPloGmTqJJYwSq7yorBg
UrtxVXDpThGPPtPdxtbM5W8G1h1KgUKxVdooucqOwmIZfvva4LKdIh46q6PtodylbwQ2fpQGA932
JY/ewnKksFCS37gmtHAbhVeH1UfTP3j92OaP0jTXtDxQcpUphUVT6q3rQk07Y9Rt3BTIXL8q+PaH
1EksH5Rc5UuRYmnt+ubgM9silZxdG1ozP309sI5GEssLJVdZk1ksrd27Ifxcpba83m7NXvFmYNNH
aWptlRlKrnKnsGBSu7k5uKTyRhs3HstcvjKw8XAKFAqtckOrfyqAwqIZ7dbVQZnBpaOEWx5UINuC
2UvfDGz+KEUjiWWJ3tTKILO2NL9hdejpbRUxw3710cwPX2/dfCQFCt3h5Yne14qhsGhKvWNdaFlL
rLxn2L/dmpnXHHj7cApkKsmXLUquSqJI0RS/flVg0fayHW3ccCz9szcCaw+lQaHYKmeUXBVGYfE0
v3t9WMyjz3pocyBz2ZvBt4/QSGL5o+SqPAoLJrVbVoee2xHVsHzia/2xzGVvBjYdSdEs+UpAyVUs
CCDOrASFRVLazauDTS1xvS8lP94JZi9fGVj/YUqsTiJHgd708kLJVRQca6xyfV8LqMKcdaGwaJrf
uCZYBvt5rTma/uG/WjcdFmkpNQJoOLHG/O9Dq5xGifIr74R5p8sYAgP4t4G2B6e6h7iNkOMgyG2s
sGhSvWt9uKRHGze2Zq9fFdws1Cx5BFBxVj/rojrPVWOcLqMEwnxhlQ1KrsJDNCjSjBrTtBrzitn+
MTVmyHEQpMCkSG1p7fpVgcU7SnK0cWNr5rI3W9ceSgu0lzwC5Hj9AOvyBu/ZNeYaq2ygAxwLgJKr
GBAgiwgAs3qZXzjH96VhVaAJU/aSWTzN71oXXrKjxLbEeSeY/ekbrW9/lAaDMNGAADneOMi2eJb3
DKcBABjQNvcFQclVeIxxxKTakVMTvKbnG/3fHekAYOJ0G9tHGxdtj3JBGoOns/Zo+tI3W7e0dxLF
ofJZA6xNDd6R1Yb2f8FRlOZ1maHkKgYEyH2mkuQxSwtmur8zvAo4ihNebWntpjXB5btiel/K6b0T
zF711scjiYJAgBw/Z6B1RaN/sMPwyb/OIQpT1ywrwrzx5e64m9djlhfM9Pz0TIdAA+cKi2f4/GbR
RxsPRNWfvxV4W6hZ8hoyBt8f7Vje6BvipF0MioFeZd14zfJDU90+s7xwezSa1ITo9cgsmuJ3bQib
FeknI6tk8So0CRUf2ty28qBI87Y0NBukeeOc88e7XEZqChQJvdB6cpvk+6e4n5rh8dtkEGRigsLa
UtoNqwLP7RBuP69olt++LrRkawRkJsqdq6HVyG6b6Lpviptiq5jotdbfD0fYn6jzOswyqGJEhcxi
Gf6LteGlIp0elNHwtvXhZ7dFQAJRWlscFYXdMdk9f7xL70upONRbFMJ3h1apHG9ZG/oopgqx7E5h
oZR269qginjFaKfuE5JCGf7QprZFWyMcEXS/mnYa2s3S/PGu68c6jYJcUiWhNpcovj/cfu8Ut9em
iNNtDCf5TWtCK/Qebcxo+MDb4cc3hzkXKLYUhc0b57p1fDXFli4ouQTyoxH2BTM9XpsiygohhSXT
fF5z8Cn9RhsTKp+/OvTUlgjnIERrFAA0NBmk+6Z4bp3gEmdWRqWhF14gDOA7Q6sW1nlG+k2gihJe
0ZR2z4bwczt0OD0oofLb1ocX7YhogsXW7ZOqrx/rpGU9OqLkEs63zqj61Wx/3QCrOOEVTmo3rQ4u
2RktZkc2kuV3rg8veDvMOYCs94vQjmOVUbplguv2iS5xtqWoTPTyi2iS3/TLRv/MflZxuo3RtHbb
6mBTS5FGGzMa3rE+vGBrBCRhllJzVCR22+Tqmye49L4UQsklqsEOZXmj75xBNlDFWCGksHCa37wm
WITTg8IZfsu60KJtEVUTqCRvN0l3ne2+/iynSZB+a2Wj5BLXMJdhcb13zkCBuo1tSX7r2lBTSwFH
G1MaPrgp/NSWiCZSbBkM0rxxrpsnOI0UW2Kg5BLaUKfhmVne2QNs4oRXIsOvbw4s2FqQAzjej6mX
vdn6+OY2RGFK8ioajezes6tvm1gt4HKoikXJJboRLsOSBm/9AGFqXjKLpfg9G0NPb4vk8rrV5+bW
zA/+79ivWmIcBVrcYzRKd05yX3uWU4R1peQTgtwg5FTOcBoW1nnr27uNIlBYKKndvDp427rQ3kiu
54+ncXxlX/zSNwJvfJAEJkxscbSbpFsmOm+d4KLalmgEuUfIaYxxG5c3+ur6WyG/7Zxuk1laxUff
Dn/v/479+UCiJ4+0P6besjZ0yT+PbfwoBQZhdoDgKEvspgmuW8ZX630p5ARo3WLJGOIwLG/wXr4y
8H8HkkLs8SIxQFh7KP3jtsDcfolz+1vO62ftZe3CzKtAWlu0PfrKvvj2YA44CnRyDwcAuOosx7xx
1NoSFCVXKRnqMi6u910Brf/cnxSiecIAFBZIqr/dFXvtQGKc1zSnr2VGrXmYy+CzyEYJvjjLPMMx
muVbA9lVH6XfPJx+43AKcxwUSZR6PLSf3MMvGlp1x8RqszhXRT6PkqvEDHUanq7zXYmtr7+fEuWc
LpkBQjLLVx9KrT6SNknMbZbO9psGOAzDnIZpNaYqgxTJ8j2R3IcJ7b1obuOxzM5wNpVr36GdCdTU
go83ZR5sW1jn8ZgFmblPToCSq/SMrDYsa/D96PXAyvcTQrS84DNH3CBkVP5RnL8WVwFAUaRqk2SW
WUbDSJbnOHD+8QgpY6JM1/rExyf3PDPTO9Bu6PnjkcIR6euOdNoQp2HRLE/DQJsoo42fYAAS6/iH
MVXD1oR6MJo7llAzKnKOAJ/8V70v9TgIoPK6AdamBt8nJ/cQYVFylarRbmNTo7e+vw2yIszyOhF2
XJB9/G/ElMP6AbamRt9gB3VESgAlVwkb4jAsbfSeM8giyiTVEoUAWZw7yNrU4B3mpNZWaaDkKm3D
nIbFDb45A20UXt2EACpvHGB55uNTqUlJoOQqeUMdhqfqvLPF2VWihCBADmcPtD1/jn+4i2KrlFBy
lYPR1YYVs31fGVYFKhfl3NmSoPLGQdZn6rwD7FTbKjGUXGViQJXy3CzvxaMdAEAtr9NDgBzO6m9t
avCOopHEEkTJVT5qrfKzdd5LxzhAQxBssoRwcrxhgKWp0TfYQbFVkii5yorDKD0+3XPFWCcghddJ
IECWnzvIuqzBN5RK8iWLkqvcVBmkh6d5fnqmEzhSzet4CKDy2QOtC2b5aCSxpFFhsgxVGdiCmR4G
uHRrFECYPZF117G4x7qi0U8l+VJH7195ssjs4akeBrDknQgAUHgBAOR4wyDrojofxVYZoLewbLlM
0mPTPSqH5TujgAIvuykCBFB5/UDbsgbvGVSSLwtU5ypnVQbpqZneS8c4QavgSaoIkOMN/a1NFFtl
hJKrzFUZ2BMz3FeMq9SCPQKofM4gW9NsP5XkywklV/mzKdJDU9w/HeMADqBVUnghQI6P9psenOYe
QjtAlBdKropgN0pP13mvHOcyyqyCwkvlg72mR6d7JvlMel8KyTNKrkphltkT090PzfC4LRKoFRBe
WT7IY1rW4L2gv1XvSyH5R8lVQYwyu+4s58J63yCXAXJlHV4qNgy0vjTHN7uPRe9LIQVBnf+K8x9n
VJkkaX5zYH9bDmTxdlXuIY6gYcMA61Ja3FPWqM1Vib4+2Prbc2vq+lnKbcBRQ0Vi3x7hWNbop9gq
b5RcFWpKjemFc/w/O9NhVKRy6DkiQA69NvmeKe5F9d4zaCSx3NEbXLkG2ZUnZnhGVBsf29x2KKqW
cM8RAVQcW2u+c7Lra4Nsel8NKQZqc1U0qyJde5Zz+Wz/5N5m0Eqz56ghcLxoiO2lOX6KrcpBbS4C
5/WzDHHUvPButGln7EisdBpfCKChwyJdO851+ShHrZWOpK4g1OYiAABnOJV7znb/4fyaCwfbJAag
it3+Qmifkjajr2X5bP+dk6optioNtblIBwYwo9b8u3NrftkSXdISezecVXMoYvtLQwDo7TRcOcZ+
6SiH10yZVYkoucjn2A3s6rOcFw+3P7s98uy2yJGICoyBIkZ+aQgce7mM5/ezXDXGMYHW9FQwSi5y
AtUm6faJ1VNrzL/bG28+nNkVzoKma/uLI2josslfHmT7/nA7zYwnlFzkpOb0tczpa9kcyL76XuLP
+xPbAhlQERQJoFj7FCIAImhYZVUaepsvHlb1jSE2hYnQ/CM6o+QipzHeaxznNV4yrGrDscwf9sb/
fiiVzPCO5GKFaYW1BxYCSGyM1/StM6oaeptHVhs95pIcUBJ4pKOEUXIVSUnfvgxgmMswzGW4cIB1
3dH0Xw4k3w5mD8VzhxOapuKnW0Wz7mbZJ1GFAAwkhfWxKVP95rn9LA19LCW9jkcCxjkAL/AhchyS
KlbUMXUVnVwSYxoWJVQQJCFK3D1VbZLO72+d28+S0eC9aO4v7yffOJR6L6omVB7PYSLHNQ1BA4D2
FDtJkLXn1Cd/BgAGBkVymySnSepnU+b0tfzbQOswp8Eol/yL5jJK47ymw3GVyUwpzLNBgFSWe81y
6b9aXVDRyWWSmdck7clx4IV8zzlykzy4jFbSyYxZFRjjNo5xG28Y5wqktPfj6oFo7r2Yurk1szei
RrM8mNEiGc5V3hFknyUBM0oWWZIZVBmlflXy6GrjWI9xvNc02mNwGSS5jE4q6lMlPzXT09jHLDFW
pbC8f0syBjmOR5PaN8+oMldSdJXPx6kbGMDtk6qb+1hSGhboPUcACcBnkRvLdDhMZlBjlWus8tl+
EwCE0jyc0dqy/IO4ejSptaa1aBb5xy0sBFAYsxqY3yw7jZIiMbdJ6lulDHYopvL91A20K9ec5dT7
KspNRScXAFzY33oh7ZmZP26z5DZLADCRJluRQirJwRpCSIWj5CKElB5KLkJI6aHkIoSUHkouQoiQ
GJxiwJmSixAiJAaKdNKAouQihAhKOXk+UXIRQoTEwEC9RUJIKUEAYMrJ14FRchFCuqZI60oZ9RYJ
IXnCAePFOVqYKvSEkHxROXyU1Hr+OKfHQKE6FyEkLyJZHs0WaRND6i0SQvLjaEorSosLgFGFnhCS
J5tbM5qGUIRzTBgYqM1FCMmL1w4kuFb4Cj2izMAqU5uLENJj24LZrcHcp2emFJIiMZtCY4uEkB77
28FkOKNBUbbeViRmNVCbixDSM0eS6st7E2qWF+McKwQDAyu1uQghPfTKvsS2QKZYM+jBrDCfhZKL
ENIDB+Pq8l2xTIYXKbkQLAqrtZ70iB9KLkLIaSDCr3bHd7RmTjVPId+/0iyz3jb5ZP+dkosQchp/
/zD11NthlRdjSLEDgxqrbDdQb5EQ0i2HEuota4OBZJGGFAEAEJgine03n+JHKLkIIScVzfKb14Y2
H82AsYhZgWiS2YzaUx02TMlFCDmxQEq7pjn4612xoo0ndkCoNknjvKdKLqXTD0YIqSBHktpVbwVe
3R0DiRWvvPWxszym3rZTpRO1uQghx3svmrt6VeDV3TGQWfEbXMDg7BrTqatq1OYihHzOH/cnHt0S
WXM4BZJU/NYWIFqN0nl9Laf+zZRchJAOrWntqa2RRduibe0jicWPLQBAmFJjHu02nvqnKLkIIRDO
8H8cTD67I7rycBpUPNU+yoWGcF4/i9N0mkIWJRchFa01ra0/mlmyM/rXD5K5LILM9IwtDu4qeXYf
82mvgJKLkIqTVnF/LNcSzm0OZDa3Zt/8KB1PqqBIemZWuxyvq7UOcxlP+4OUXHlwNKkVbSEqIZ2k
cp5SIa3xtIppDYMZfjihHk5orSktkNbej6nvtuXSSQ0YgMKKtyDxFDhazdI3hticnZj1SsnVU8t3
xRZui6i8GBtzE9J5HFHlkOOoclA5JlSM5jhmObTvIi8zkABOV04qKhUn9LWc28/amZ+l5OqRppbo
/NWhSEyliXFERJ98nbKP/1eRwKD3VZ0QR9kofWuwrcYid+bHKbm6b/mu2A3NwUiKi/XFRUgp4nCm
33jhgE41uIDm0Hfbil2xG5oDbWmuf1GTkFLHAWT2vWH2wY7ONggpubrj+V2xec3BcIoXb98PQsoY
4vRe5u8Mrer836Dk6rLnd8Wuaw62FXO7IkLKGEcms6vPdNRaO1XhakfJ1TXLd8WubQ5GUhp1EgnJ
D4SvDbZ9aWBnK1ztKLm6oKklOq85GE2q1NoiJD809Nnk68c6T3Eo7AnR2GJnLW+J3dAciqQ06OJL
TAg5MQSZwZVjnFNrzF39q/Qh7JQVu2Lzm4NtaeokEpI/Kq/vb718jKMbfRgRk0u0bGgfSWxLU0me
kPxR0W1TrjvL6e/c1NPjiJhcQlmxK3bdKhpJJCSvOIIEl41xnNfP0r0HoOQ6leW7Ytc1ByIplTqJ
hOQNAnD8ypCqm8a7DN3dqICS66SaWmLzm4PRJJXkCckrlY/2mR+aWu3owUloNLZ4YsvbF/ekOMUW
IfmkYh+XYXG9d0QnNuE6BfpYnsCKXbEbVgVpTSIheaai3yY/U+et69XlaRDHoTbX8Z7fFZu3qr21
RbFFSP6oWG2RH5ru+dogW88fjJLrc1bsil3XHIxSbBGSXxpajdLdU9w/GGHPy+NRb/FTTS2x69oX
91BsEZJHKlcUdvvk6ivHOPL10aI2V4flLbEbVtNIIiH5pqLdotw43nnLBFceH5WSC4C2CSSkQLLY
22V4cFr1JcPy00n8BCXXJ4t7aJtAQvIqywdVG5+p917U6T2aO6/Sk+v5XdHrm0NtSVpKTUj+cACO
42pNj0z3zu3u+p5Tq+jkamqJzltNI4mE5A8CaNxgkL83yj5/vHNkz6abnkLlJldTS3Te6hCV5AnJ
G46A0MdpuHVC9Y9G2s2FLL9UaHItb4ndQLFFSB7luGyQvj7Ydu045/Su7xTYVZWYXCtaaJtAQvKH
I3AcUm388WjHtWc5LUX5WFVccj3/Lm0TSEieIIDGQWLfHuG4bqzzbL+paL+5spKr/cAxOrmHkJ5C
AETgMKmX5Qcj7BcPq3L2YMuabqig5FreEru+ORClIzAI6bb2wEKQGBvjM/1kpP2rg2z9qnSIkUpJ
rqaW6A3NIYotQroJATQEAJtJGug0XDrS/vUhVX1t3dlCPi8qIrlW7IrduDpI2wQS0h0cQUWQWV+H
YVov00UDrOf1s3bpPOpCKP/ken5XbP6qYJjWJBLSJRxBQ+DgsCujnIbpvc1fHmir713w6Q6dVObJ
9fyu2PWrgm1UkifkFPCTPyAgAAcA9NqVqTXmyX7TJJ9plNs40C5WVoh1Nfn1/K7Ytc3BKMUWqUx4
3P/FE/9A+4eDMQDoYzeM8xrHeoxneYyDHYZBDsVr1rlXeDJlm1xNLbF5zcFoShWrtoUAHHv+MIR8
6rMtpnaMfZxHAIwxBhIDiUmMgQQgMWAMJMaMEtRYlKFOZajLOMShjPWYettkp1FyGKXuniVWPOWZ
XB3bBIo2kqgiSOC0yBIDSi/SQwxAkZjCQJGYIjFDxx/AKDGbgdkNUpVBqjKw9v+1GyWbIlkUZpGZ
RWFmhVUZpP5Viscst/9F8aPqOGWYXCt2xW5oFqy2hQAc+zmUrwy2zeptUSRqeJGekhlYFFalSDaD
ZDMwm9L+v0wpuRDqlnJLro+3CRQstlR+Zq352Xwc1kQIgTJLrk8X9wi1JlHDUX7TikbfJF/xVnUR
Ut5EKgP1zPKW2LUCxpaKI7zGX872U2wRkkdlklxNLbF5q4PRpCpYbPGhHmNTg29yEdfQE1IJyiG5
2kcSI6JtE5jjg6qNyxq8M6i2RUi+lXyda8UuIbcJzPG+1cYVjb763gU5PoCQClfayfXpSKJYnUTs
X2188RzfLIotQgpDpO5VF63YFbuuOdiWFCy2NBxSbfj1HD/FFiGFU6ptrqaW6LxmwWbJI4CGwzyG
52fXTK+lkjwhBSTMx74rmlqi80Vb3IMAKh/hNa6Y7afYIqTQhPnkd9ryXe0jiSJtE4gAKh/pMy1v
9M2opZFEQgquxHqLK3bF5q8Ktom2TaDKR9eYmxq8Uwt/zBwhBEoruZ7fFZu3KtCWEim2EEDDs2rN
z8/2TfBSJ5GQIhGmw3U6HSOJ4sXWxFrzCxRbhBRXabS5lrVE5zeHxNomEAE0nNzbvLTeN85r1Ptq
CKkswgTByS3fFb1RtAPHEEDFKb3NSxsotgjRgehtrhW7YvObQ8JtE6jyaf2si2d5x3ootgjRgaDJ
1Z5Sz++KzWsWsCTPZ/SzLqn3jnZTbBGiDxGTq3032hffjV3fXpIXZ3EPAmg4q791Wb1vmMug99UQ
UrmEqRx9hklm7QeOtQm1TSACaDi7v4ViixDdidfmktiOUPZP+5OhhGC1LQ3nDrQ+W+cdSrFFiN7E
Sy6F/deBJCKIFVsqP3ewbWGdd6iTYosQ/YmXXO3nXQqTWoAIKpw/xLZwpncIxRYhYhAxuUSKLQAN
Lzyj6tk670C7kK8VIRWJPo0nhwAafumMqkV13r5V9EIRIhD6QJ4EAmj4taG2hXW+3jZZ76shhHyO
iLMi9McRVP7/hlUtrPNSbBEiIGpzfQFH0PCbI+xPzvT2tlJsESIiSq7PQwQNvjXSsWCmp8ZCsUWI
oKi3+BkIoOF/jLI/Q7FFiNiozfUxBNDw4lGOBTO9bhMFOiFCo+QCAACOwPGHo51PzPS4jBRbhIiO
PqUdJfmfnEmxRUjJqPg2F0fQ8KdjXY9NczsotggpEZWdXIigwc/Guh6d7rEbxFlzRAg5jQpOLgTQ
8PLxrkenemwUW4SUlEpNLgTQ8LKxrsemua3ibKdDCOmciqzscASV/+xMx2PT3VZxzhMihHRa5X1u
20cSz3I+Mt1jo9gipDRV2EeXI2j4/THOR6d5aCSRkNJVSZ9eRODwvdGOJ2a4XTRLnpBSVjEfYARQ
8Tsj7U/N9LhNtCaRkNJWGcmFACr+x0jH0xRbhJSFCpgVwRE0/NYI+5MzPB4zxRYh5aDc21wcQcNv
DLc/OdNTQ9sEElIuyjq5EEGDrw6zL5jp6WWtgNYlIRWjfJOr/eSeoVXPzPT0sVFsEVJWyvQjjQAa
XjSkatEsT1+KLULKTjm2udpja7BtUZ2XYouQslR2yYUAKp4/yPp0nac/nUpNSJkqr+RCBJXPGWR9
us472GHQ+2oIIYVSRsmFCCo0DLA9W+cd6qTYIqSclUt/CgE0mDXAuqzBewbFFiHlrizaXAigYV0/
y5J6ii1CKkLpJxcCqDijr/m5et8IF8UWIRWhxJMLAVQ+ta9l0SzfqGqKLUIqRSknFwKofFIfy+JZ
nrM8Rr2vhhBSPCVboUcAlY/vZWlq8I2l2CKkwpRsm0vDsbXmFbMptgipRKWZXCqOrTEtb/SNo9gi
pCKVYHLl+Bi/aVmDd6LPpPelEEL0UVJ1LgRQ+QifaWmDd7LfrPfVEEJ0U1JtLpWf4TE2Nfim1VBs
EVLRSqTNhQAaDvGYXpzjm0qxRUjFK5E2l4bDPMYXz6HYIoQAlEBytde2vMZfzvZNq6XYIoQAiJ5c
H5fkVzRSbBFCPiVwciGAykf6Tcsb/BRbhJDPErhCr/KRfvPzs31T/DRvixDyOUImFwJoOKbG/MvZ
/ok+miVPCDmekL1FDcfVGH95DsUWIeTEBGtzIYCG42tNTQ2+CV6KLULIiYnU5kIAlU+sNS9r8E2g
NYmEkJMTps3VHlu9LUvqaSk1IeQ0xEguBND4pN6Wpkbab4sQcnpiJJeGZ/exLG/wjXFTbBFCTk/v
OhcCqDidYosQ0hW6JlfHgWOWpQ1eii1CSOfpl1wIoPKZ/SzP1XtHU2wRQrpCpzoXIqg4s7/1uVkU
W4SQLtMjuRBAw1kDrEvrfcPpVGpCSNcVPbkQQMPGAbYl9d6hTootQkh3FLfOhQAazhloXUqxRQjp
gSImFwKoeO5A66JZ3jMotgghPVCs3mJ7bA22LazzUGuLENJDRUkuRFDx/CFVC2d6hlBsEUJ6rPDJ
hQAqXnRG1aI6b3+7GIuNCCElrsBRggAafnWYfWGdp4+NYosQkh+FTBOOoOE3RziemunpZZX1fqaE
kPJRsLFFjqDht0c6FlBsEULyrTBtLo6gwXdG2Z+c4fFbKLYIIXlWgORCBI7fG+18Yobba6bYIoTk
X76TCwFU+MGZjieme6pNem/+RQgpU3lNLo6gwU/HOh6f7rEbKLYIIYWSv3zhCByuHOd8cgbFFiGk
sPIUMRxAw0vPcj4y3WNTKLYIIYWVj94iR+Dw3VGOh6a6rQrT+xkRQspfj9tHCMDxK0Ntj1NJnhBS
LD3OGg3nDrItnOmtoemmhJBi6UFyIUAO6/panp3p7VtFaxIJIcXTg+RS+cRe5oWzvENpL3lCSHF1
N7lyfLjPtHCW9ywPndxDCCm2biWXin1chqUNvqk1Jr2vnxBSibqeXCr32uQXz/HP6mXW++IJIRWq
C8mFACmVyyb5V3P8jX0sel85IaRydSW5EBxG+Zdz/Bf0t+p92YSQisYQsZM/ejCuZTkf4qCRREKI
zrqQXIQQIghar0MIKT2UXISQ0kPJRQgpPXlLrlQ2t/HAoQwVzQghhff/AaLAidJOjNiNAAAAJXRF
WHRkYXRlOmNyZWF0ZQAyMDI0LTAyLTE5VDAyOjI1OjA0KzAxOjAw7L6yJgAAACV0RVh0ZGF0ZTpt
b2RpZnkAMjAyNC0wMi0xOVQwMjoyNTowNCswMTowMJ3jCpoAAAAASUVORK5CYII=" />
</svg>
          </div>
          <div class="ml-2">Your selection will be saved</div>
        </div>
        <b-form-group class="mt-4">
          <b-form-checkbox-group v-model="selected" stacked>
            <div v-for="(item, index) in statuses" :key="index" class="d-flex justify-content-between align-items-center mt-3 border-bottom pb-3">
              <div class="d-flex align-items-center">
                  <div class="icon font-size-20">
                    <img :src="renderIco(item.icon)" :alt="item.text" class="img-fluid svg"/>
                  </div>
                  <div class="data ml-2">
                    <div><b>{{ item.text }}</b></div>
                    <div class="mb-0">{{ item.description }}</div>
                  </div>
                </div>
                <div>
                  <b-form-checkbox :value="item.id" :inline="false"/>
                </div>
            </div>
          </b-form-checkbox-group>
        </b-form-group>
      </b-modal>
  </div>
</template>

<script>
import Statuses from '@/FackApi/json/Feed/Statuses'

export default {
  name: 'AddPostStatusModal',
  props: {
    title: {
      type: String,
      default: 'Who can see this post?'
    }
  },
  data () {
    return {
      statuses: Statuses,
      activeStatus: [Statuses[0]],
      selected: []
    }
  },
  methods: {
    changeStatus (status) {
      this.activeStatus = this.statuses.find(x => x.id === status.id)
    },
    save () {
      if (this.selected.length) {
        this.activeStatus = []
        this.selected.forEach(id => {
          this.activeStatus.push(this.statuses.find(x => x.id === id))
        })
      } else {
        this.activeStatus = [Statuses[0]]
      }
    },
    renderIco (ico) {
      return require(`@/assets/images/icon/${ico}`)
    }
  }
}
</script>

<style scoped>
  .logo-wrapper {
    width: 45px;
  }

  .status-description {
    font-size: 16px;
    padding: 0px 10px;
    border: 1px solid var(--iq-secondary-hover);
    border-radius: 12px;
  }
</style>
