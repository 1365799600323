<template>
  <div class="vids-logo-2">
    <span class="v">V</span>
    <span class="i">i</span>
    <span class="d">d</span>
    <span class="s">s</span>
    <div class="i-dot"></div>
    <span class="simple-text ml-3">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'VidsLogo'
}
</script>

<style lang="scss" scoped>
.vids-logo-2 {
  position: absolute;
  font-size: 22px;
  font-weight: bolder;
  left: 30px;
  top: 1px;
  min-width: 300px;
  margin-right: 20px;

  .simple-text {
    font-weight: 600;
  }

  .i-dot {
    position: absolute;
    width: 4px;
    height: 4px;
    top: 7px;
    left: 25px;
    background-color: rgb(237, 28, 36);
  }

  span {
    margin-left: 4px;
    &.v {
      color: rgb(0, 175, 240);
    }
    &.i {
      color: rgb(237, 197, 56);
    }
    &.d {
      color: rgb(81, 204, 133);
    }
    &.s {
      color: rgb(24, 127, 242);
    }
  }
}
</style>
