<template>
  <FormSelect
    v-model="selectValue"
    :label="label"
    :required="required"
    :error-message="errorMessage"
    :submitted="submitted"
    :options="options"
  />
</template>

<script>
import FormSelect from '@/components/homeiz/common/form/FormSelect'
export default {
  name: 'TimeZone',
  components: {
    FormSelect
  },
  props: {
    value: {
      type: [String, Number]
    },
    required: {
      type: Boolean,
      default: false
    },
    submitted: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      label: 'Time zone',
      errorMessage: 'Please select a time zone'
    }
  },
  computed: {
    selectValue: {
      get () {
        return this.value
      },
      set (selectValue) { this.$emit('input', selectValue) }
    },
    options () {
      return [
        { value: 1, text: 'Pacific Time' },
        { value: 1, text: 'Eastern Time' },
        { value: 2, text: 'Central Time' },
        { value: 3, text: 'Mountain Time' }
      ]
    }
  }
}
</script>
