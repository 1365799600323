<template>
  <div>
    <b-form-group>
      <label>{{ label }} <span v-if="required">*</span></label>
      <vue-timepicker
        class="timepicker-traditional"
        v-model="timeValue"
        format="hh:mm A"
        :minute-interval="30"
        :placeholder="placeholder"
        close-on-complete
      >
        <template v-slot:icon><i class="ri-time-line"></i></template>
        <template v-slot:dropdownButton>&#x02263;</template>
      </vue-timepicker>
      <div class="d-flex justify-content-between">
        <div class="invalid-feedback d-block" v-if="error">
          {{ errorMessage }}
        </div>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'
export default {
  name: 'FormTime',
  components: {},
  props: {
    value: {
      type: Object
    },
    label: {
      type: String,
      default: 'Time'
    },
    required: {
      type: Boolean,
      default: false
    },
    resetButton: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: 'Please enter a time'
    },
    placeholder: {
      type: String,
      default: null
    },
    submitted: {
      type: Boolean,
      default: false
    }
  },
  validations () {
    return {
      value: {
        requiredIf: requiredIf(function () {
          return this.required
        })
      }
    }
  },
  data () {
    return {}
  },
  computed: {
    error () {
      return !this.$v.value.requiredIf && this.submitted
    },
    timeValue: {
      get () {
        return this.value
      },
      set (timeValue) { this.$emit('input', timeValue) }
    }
  }
}
</script>

<style lang="scss">
.timepicker-traditional {
  width: 100%;

  &.timepicker {
    width: 100%;
  }

  .display-time {
    width: 100%;
    box-shadow: none !important;
    color: var(--iq-homeiz-blue-2) !important;
  }

  .controls {
    left: 120px !important;
    right: auto;
  }
}
</style>
